import React, { useState } from "react";
import { forgetPassword } from "../../api/actions/UserAction";
import swal from 'sweetalert';

export const ForgetPasswordComponent = (props) => {
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    if (!email) {
      swal("Please Enter Your Email !", "", "error");
  } else if (!/\S+@\S+\.\S+/.test(email)) {
      swal("Invalid Email !", "", "error");
  } else {
      forgetPassword({ email }).then(res => {

      })
  }
  };

  return (
    <div className="limiter">
      <div className="container-login100 page-background">
        <div className="wrap-login100">
          <div className="login100-form validate-form">
            <img
              alt=""
              className="login100-form-logo"
              src="assets/img/Admin_Vertical.png"
            />
            <span className="login100-form-title p-b-34 p-t-27">Forget Password</span>

            <div
              className="wrap-input100 validate-input"
              data-validate="Enter username">
              <input
                className="input100"
                type="text"
                name="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <span
                className="focus-input100 text-left"
                data-placeholder="&#xf207;"></span>
            </div>
            <div className="container-login100-form-btn">
              <button className="login100-form-btn" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
