import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export const TransactionsTable = (props) => {
  const { tableData, minIndex, maxIndex } = props;

  return (
    <table
      className="table table-hover table-checkable order-column full-width"
      id="example4"
    >
      <thead>
        <tr>
          <th className="center"> Transaction Id</th>
          <th className="center"> Customer Name </th>
          <th className="center"> Purchased On </th>
          <th className="center"> Quantity </th>
          <th className="center"> Product Price(KWD) </th>
          <th className="center"> Action </th>
        </tr>
      </thead>
      <tbody>
        {tableData?.docs?.map((item, key) => {
          return (
            <Fragment>
              {key >= minIndex && key < maxIndex && (
                <tr className="odd gradeX">
                  <td className="user-circle-img">
                    <span>{key + 1}</span>
                  </td>
                  <td className="center">{item.vendor.fullName}</td>
                  <td className="user-circle-img">
                    <span>{moment(item?.date).format("YYYY-MM-DD")}</span>
                  </td>
                  <td className="center">
                    <span>{item?.amount - item?.customer?.cart.amount}</span>
                  </td>
                  <td className="center">
                    <span>{item?.amount}</span>
                  </td>
                  <td className="center">
                    <span>{item?.status}</span>
                  </td>
                  <td className="center">
                    <Link
                      to={{
                        pathname: "/todayDetails",
                        state: item._id,
                      }}
                      className=""
                    >
                      <span>View</span>
                    </Link>
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
