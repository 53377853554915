import React from "react";
import { Link } from "react-router-dom";
import { ACCESS_TOKEN } from "../Redux/Actions/ActionTypes";
import { authenticate } from "../Redux/Actions/Auth";

class SideBar extends React.Component {
  menuList = [
    {
      key: 0,
      mainMenu: "Dashboard",
      iconName: "dashboard",
      page: "/dashboard",
      active: true,
    },
    {
      key: 1,
      mainMenu: "Order Management",
      iconName: "business_center",
      page: "/order",
      active: false,
    },
    {
      key: 2,
      mainMenu: "Vendor Configurations",
      iconName: "account_circle",
      page: "",
      active: false,
      subMenu: [
        { key: 2.0, subMenuName: "New applications", page: "/newVendor" },
        { key: 2.1, subMenuName: "Registered Vendors", page: "/vendorList" },
      ],
    },
    {
      key: 3,
      mainMenu: "Products",
      iconName: "business_center",
      page: "/products",
      active: false,
    },
    {
      key: 4,
      mainMenu: "Professionals",
      iconName: "account_circle",
      page: "/professionals",
      active: false,
    },
    {
      key: 5,
      mainMenu: "Settings",
      iconName: "settings",
      page: "/settings",
      active: false,
    },
    {
      key: 6,
      mainMenu: "Category Configurations",
      iconName: "business_center",
      page: "",
      active: false,
      subMenu: [
        { key: 6.0, subMenuName: "View Categories", page: "/viewCategories" },
        { key: 6.1, subMenuName: "Add Categories", page: "/updateCategory" },
        { key: 6.2, subMenuName: "View Subcategories", page: "/subCategories" },
        { key: 6.3, subMenuName: "Add Subcategories", page: "/updateSubCategory" },
      ],
    },
    {
      key: 7,
      mainMenu: "User Management",
      iconName: "business_center",
      page: "/user",
      active: false,
    },
    {
      key: 8,
      mainMenu: "Commission Configurations",
      iconName: "business_center",
      page: "",
      active: false,
      subMenu: [
        { key: 8.0, subMenuName: "Custom Commission Packages", page: "/customCommission" },
      ],
    },
    {
      key: 9,
      mainMenu: "Transactions",
      iconName: "directions_transit",
      page: "",
      active: false,
      subMenu: [
        { key: 9.0, subMenuName: "Today Transactions", page: "/todayTransactions" },
        { key: 9.1, subMenuName: "Vendor's Transactions", page: "/vendorTransactions" },
      ],
    },
    {
      key: 10,
      mainMenu: "Notifications",
      iconName: "directions_transit",
      page: "/notification",
      active: false,
    },
  ];

  setActive = function (activeMenu) {
    let mainMenuIndex = activeMenu ? Number(activeMenu.split(".")[0]) : 0;
    let subMenuIndex = activeMenu ? Number(activeMenu.split(".")[1]) : 0;

    this.menuList.forEach((element) => {
      element.active = false;

      if (element.subMenu) {
        element.subMenu.forEach((sub) => {
          sub.active = false;
        });
      }
    });

    this.menuList[mainMenuIndex].active = true;

    if (this.menuList[mainMenuIndex].subMenu) {
      this.menuList[mainMenuIndex].subMenu[subMenuIndex].active = true;
    }
  };

  constructor(props) {
    super(props);
    this.setActive(props.activeMenu);
    this.state = {
      menuList: this.menuList,
    };
  }

  handleLogout = () => {
    this.props.dispatch(authenticate(false));
    localStorage.setItem(ACCESS_TOKEN, "");
  };

  render() {
    const { menuList } = this.state;

    return (
      <div className="sidebar-container">
        <div className="sidemenu-container navbar-collapse collapse fixed-menu">
          <div id="remove-scroll">
            <ul
              className="sidemenu page-header-fixed p-t-20"
              data-keep-expanded="false"
              data-auto-scroll="true"
              data-slide-speed="200"
            >
              <li className="sidebar-toggler-wrapper hide">
                <div className="sidebar-toggler">
                  <span></span>
                </div>
              </li>
              <li className="sidebar-user-panel">
                <div className="user-panel">
                  <div className="row">
                    {/* <div className="sidebar-userpic">
                      <img
                        src="assets/img/dp.jpg"
                        className="img-responsive"
                        alt=""
                      />{" "}
                    </div> */}
                  </div>
                  <div className="profile-usertitle">
                    {/* <div className="sidebar-userpic-name"> John Deo </div>
                    <div className="profile-usertitle-job"> Manager </div> */}
                  </div>
                  <div className="sidebar-userpic-btn">
                    {/* <a
                      className="tooltips"
                      href="/userProfile"
                      data-placement="top"
                      data-original-title="Profile"
                    >
                      <i className="material-icons">person_outline</i>
                    </a>
                    <a
                      className="tooltips"
                      href="/emailInbox"
                      data-placement="top"
                      data-original-title="Mail"
                    >
                      <i className="material-icons">mail_outline</i>
                    </a>
                    <a
                      className="tooltips"
                      href="chat.html"
                      data-placement="top"
                      data-original-title="Chat"
                    >
                      <i className="material-icons">chat</i>
                    </a>
                    <a
                      className="tooltips"
                      href="/"
                      data-placement="top"
                      data-original-title="Logout"
                    >
                      <i
                        className="material-icons"
                        onClick={() => this.handleLogout()}
                      >
                        input
                      </i>
                    </a> */}
                  </div>
                </div>
              </li>

              {menuList.map((item) => {
                return (
                  <li
                    key={item.key}
                    className={item.active ? "nav-item active" : "nav-item"}
                  >
                    <a href={item.page} className="nav-link nav-toggle">
                      <i className="material-icons">{item.iconName}</i>
                      <span className="title">{item.mainMenu}</span>
                      {item.subMenu && <span className="arrow"></span>}
                    </a>

                    {item.subMenu && (
                      <ul className="sub-menu">
                        {item.subMenu.map((subItem) => {
                          return (
                            <li
                              key={subItem.key}
                              className={
                                subItem.active ? "nav-item active" : "nav-item"
                              }
                            >
                              <a href={subItem.page} className="nav-link ">
                                <span className="title">
                                  {subItem.subMenuName}
                                </span>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}

              {/* <li className="nav-item start active">
                <a href="/dashboard" className="nav-link nav-toggle">
                  <i className="material-icons">dashboard</i>
                  <span className="title">Dashboard</span>
                  <span className="selected"></span>
                </a>
              </li>

              <li className="nav-item">
                <a href="/order" className="nav-link nav-toggle">
                  <i className="material-icons">business_center</i>
                  <span className="title">Order Management</span>
                </a>
              </li>

              <li className="nav-item">
                <a href="" className="nav-link nav-toggle">
                  <i className="material-icons">account_circle</i>
                  <span className="title">Vendor Configurations</span>
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <a href="/newVendor" className="nav-link ">
                      <span className="title">New applications</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/vendorList" className="nav-link ">
                      <span className="title">Registered Vendors</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a href="/products" className="nav-link nav-toggle">
                  <i className="material-icons">business_center</i>
                  <span className="title">Products</span>
                </a>
              </li>

              <li className="nav-item">
                <a href="/professionals" className="nav-link nav-toggle">
                  <i className="material-icons">account_circle</i>
                  <span className="title">Professionals</span>
                </a>
              </li>

              <li className="nav-item">
                <a href="/settings" className="nav-link nav-toggle">
                  <i className="material-icons">settings</i>
                  <span className="title">Settings</span>
                </a>
              </li>
             */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SideBar;
