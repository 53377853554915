import React, { useEffect, useState, Fragment } from "react";
import swal from "sweetalert";
import { PageTitle } from "../Common/PageTitle";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";
import { Tools } from "../Common/Tools";
import { BreadCrumb } from "../Common/BreadCrumb";
import { CategoryList } from "./CategoryList";
import { PaginationComponent } from "../Common/Pagination";
import {
  deleteCategory,
    deleteSubCategory,
  getCategoryList,
  getSubCategoryList,
} from "../../api/actions/ProductAction";
import { SubCategoryList } from "./SubCategoryList";

const pageSize = 10;

export const SubCategoryView = (props) => {
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    handleData();
  }, []);

  const handleData = () => {
    getSubCategoryList().then((res) => {
      setTableData(res.data.subcategories);
      setTotalPage(res.data && res.data.subcategories.length / pageSize);
      setMinIndex(0);
      setMaxIndex(pageSize);
    });
  };

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteSubCategory(id).then((res) => {
          if (res.data.status == "success") {
            swal(`Successfully Deleted !`, {
              icon: "success",
            });
            handleData();
          } else {
            swal(
              "Something went wrong !!!",
              "You clicked the button!",
              "error"
            );
          }
        });
      } else {
        swal("Process Terminated!");
      }
    });
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Manage Categories" />
            <BreadCrumb secondPlace="Category Management" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-box">
              <div className="card-head">
                <ComponentHeader title="Category List" />
                <TableTools />
              </div>
              <div className="card-body ">
                <div className="row p-b-20">
                  <div className="col-md-6 col-sm-6 col-6"></div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="btn-group pull-right">
                      <Tools />
                    </div>
                  </div>
                </div>
                <div className="table-scrollable">
                  <SubCategoryList
                    minIndex={minIndex}
                    maxIndex={maxIndex}
                    tableData={tableData}
                    handleDelete={handleDelete}
                  />
                </div>
                {tableData && tableData.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PaginationComponent
                      pageSize={pageSize}
                      current={current}
                      total={tableData && tableData.length}
                      onChange={handlePagination}
                    />
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
