import React, { Fragment } from "react";
import ExcelJS from "exceljs";

export const Tools = (props) => {
  const { id, name } = props;

  const exportToExcel = (elementId, sheetName) => {
    const fileName = `${sheetName}.xlsx`;
    const elementContent = document.getElementById(elementId);
    const dataContent = tableToJSONForExcelJS(elementContent);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);

    let maxColumnLength = 0;
    let columnLength = 0;
    let cellValue = "";
    let columnPadding = 2;

    worksheet.mergeCells("A4", "F5");
    worksheet.getCell("A4").font = {
      name: "Arial",
      family: 4,
      size: 20,
      underline: false,
      bold: true,
    };
    let siteDirection = "left";
    worksheet.getCell("A4").alignment = { horizontal: siteDirection };
    worksheet.getCell("A4").value = "All Orders";

    worksheet.addTable({
      name: "table",
      ref: "A7",
      headerRow: true,
      // style: {
      //     theme: 'TableStyleLight9',
      //     showRowStripes: true
      // },
      columns: dataContent.headers,
      rows: dataContent.data,
    });

    worksheet.columns.forEach((column) => {
      maxColumnLength = 0;

      column.eachCell({ includeEmpty: false }, function (cell) {
        if (cell.value && cell.value !== sheetName) {
          cellValue = String(cell.value);
          columnLength = cellValue.length;

          if (columnLength > maxColumnLength) {
            maxColumnLength = columnLength;
          }
        }
      });
      column.width =
        maxColumnLength < 10 ? 10 : maxColumnLength + columnPadding;
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      if (typeof window !== "undefined") {
        let elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = fileName;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    });
  };

  const tableToJSONForExcelJS = (table) => {
    let data = []; // first row needs to be headers let headers = [];
    let headers = [];
    let obj = {};
    for (let i = 0; i < table.rows[0].cells.length; i++) {
      let rowElements = table.rows[0].cells[i].innerHTML
        .toUpperCase()
        .replace(/<\/?[^>]+>/gi, "");
      if (rowElements != "") {
        obj = { name: rowElements };
      } else {
        continue;
      }
      headers.push(obj);
    }
    // go through cells
    for (let i = 1; i < table.rows.length; i++) {
      let tableRow = table.rows[i];
      let emptyArray = [];
      for (let j = 0; j < tableRow.cells.length; j++) {
        let rowEle = tableRow.cells[j].innerHTML.replace(/<\/?[^>]+>/gi, "");
        if (rowEle != "") {
          emptyArray.push(rowEle);
        }
      }
      data.push(emptyArray);
    }
    return { data, headers };
  };

  return (
    <Fragment>
      <a
        className="btn deepPink-bgcolor  btn-outline dropdown-toggle"
        data-toggle="dropdown"
      >
        Tools
        <i className="fa fa-angle-down"></i>
      </a>
      <ul className="dropdown-menu pull-right">
        <li>
          <button style={{ marginLeft: "16px" }}>
            <i className="fa fa-print"></i> Print{" "}
          </button>
        </li>
        <li>
          <button style={{ marginLeft: "16px" }}>
            <i className="fa fa-file-pdf-o"></i> Save as PDF{" "}
          </button>
        </li>
        <li>
          <button
            style={{ marginLeft: "16px" }}
            onClick={() => exportToExcel(id, name)}
          >
            <i className="fa fa-file-excel-o"></i> Export to Excel{" "}
          </button>
        </li>
      </ul>
    </Fragment>
  );
};
