import React, { useState, Fragment } from "react";
import { addCommission } from "../../api/actions/Transactions";
import swal from "sweetalert";

export const CustomPackages = (props) => {
  const { handleAllCommissions } = props;
  const [packageName, setPackageName] = useState("");
  const [commission, setCommision] = useState();
  const [error, setError] = useState(false);
  const [errorObj, setErrorObj] = useState({});

  const validation = (data) => {
    let errors = {};
    if (!data.package) {
      errors.packageName = "Required !";
    } else if (!data.commission) {
      errors.commission = "Required !";
    }
    setErrorObj(errors);
    return errors;
  };

  const addCustomCommission = () => {
    if (!packageName && !commission) {
      setError(true);
      swal("Please fill required fields !!!", "", "error");
    } else {
      setError(false);
      let obj = {
        package: packageName,
        commission: commission,
        commission_type: 1,
      };

      let errors = validation(obj);

      if (!Object.keys(errors).length) {
        addCommission(obj).then((res) => {
          if (res.data.status == "success") {
            setPackageName("");
            setCommision("");
            handleAllCommissions();
            swal({
              title: "Successful!",
              text: "Successfully Created!",
              type: "success",
              timer: 3000,
            });
          }
        });
      } else {
      }
    }
  };
  
  const handleCancel = () => {
    setPackageName("");
    setCommision("");
    setErrorObj({});
  };

  return (
    <div className="card-box">
      <div className="card-head">
        <header>Custom Commission Packages</header>
      </div>
      <div className="card-body row">
        <div className="col-lg-6 p-t-20">
          <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
            <input
              className="mdl-textfield__input"
              type="text"
              id="txtLasttName"
              value={packageName}
              onChange={(e) => {
                setPackageName(e.target.value);
                setErrorObj({});
              }}
            />
            <label className="mdl-textfield__label">Package</label>
            {errorObj.packageName ? (
              <span style={{ color: "red" }}>Required !</span>
            ) : (
              <Fragment />
            )}
          </div>
        </div>
        <div className="col-lg-6 p-t-20">
          <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
            <input
              className="mdl-textfield__input"
              type="number"
              id="txtLasttName"
              value={commission}
              onChange={(e) => {
                setCommision(e.target.value);
                setErrorObj({});
              }}
            />
            <label className="mdl-textfield__label">Commission (KWD)</label>
            {errorObj.commission ? (
              <span style={{ color: "red" }}>Required !</span>
            ) : (
              <Fragment />
            )}
          </div>
        </div>
        <div className="col-lg-12 p-t-20 text-left">
          <button
            type="button"
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
            onClick={addCustomCommission}
          >
            Save
          </button>
          <button
            type="button"
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
