import React from 'react';
// import { Link } from 'react-router-dom';
import NavigationPanel from '../NavigationPanel';
import Footer from '../Footer';
import SideBar from '../SideBar';
import ManageProductComponent from './ManageProductComponent';

class NewVendor extends React.Component {
    render() {
       return (
        <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white dark-sidebar-color logo-dark">
            <div className="page-wrapper">
            <NavigationPanel></NavigationPanel>
            <div className="page-container">
                <SideBar></SideBar>
                <ManageProductComponent></ManageProductComponent>
            </div>
            <Footer></Footer>
            </div>
        </div>
       );
    }
 }

 export default NewVendor;