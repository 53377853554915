import axios from "axios";

export const getProfessionals = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/admin/professionals`, {
        headers: {
            Authorization: localStorage.access_token
        }
    });
}

export const acceptProfessional = data => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/admin/professionals/${data}`, {}, {
        headers: {
            Authorization: localStorage.access_token
        }
    })
}

export const rejectProfessional = data => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/admin/professionals/${data}`, {
        headers: {
            Authorization: localStorage.access_token
        }
    })
}