import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { PageTitle } from "../Common/PageTitle";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";
import { Tools } from "../Common/Tools";
import { BreadCrumb } from "../Common/BreadCrumb";
import { filteredOrders, getOrderList } from "../../api/actions/OrderAction";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { PaginationComponent } from "../Common/Pagination";
import { TransactionsTable } from "./TransactionsTable";

const pageSize = 10;

export const TodayTransactions = (props) => {
  const [tableData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [error, setError] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = (obj) => {
    getOrderList(obj).then((res) => {
      if (res.data.orders) {
        setTableData(res.data.orders);
        setTotalPage(res.data && res.data.orders.length / pageSize);
        setMinIndex(0);
        setMaxIndex(pageSize);
      } else {
        setError(true);
      }
    });
  };

  const getFilteredList = (obj) => {
    filteredOrders(obj).then((res) => {
      if (res.data.orders) {
        setTableData(res.data.orders);
        setTotalPage(res.data && res.data.orders.length / pageSize);
        setMinIndex(0);
        setMaxIndex(pageSize);
      } else {
        setError(true);
      }
    });
  };

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const handleFilter = () => {
    let obj = {};
    if (selectedDate) {
      obj.SD = selectedDate;
    }
    getFilteredList(obj);
  };

  const handleClear = () => {
    setSelectedDate("");
    getOrders();
  };

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Manage Transactions" />
            <BreadCrumb secondPlace="Transaction Management" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-box">
              <div className="card-head">
                <ComponentHeader title="Today Transactions" />
                <TableTools />
              </div>
              <div className="card-body ">
                <div className="row p-b-20">
                  <div className="col-md-6 col-sm-6 col-6">
                    <DatePicker
                      onChange={handleDateChange}
                      style={{ height: "33.8px" }}
                      allowClear
                      value={selectedDate ? moment(selectedDate) : null}
                    />
                    <button
                      type="button"
                      className="btn deepPink-bgcolor"
                      style={{
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                      onClick={handleFilter}
                    >
                      Search
                    </button>
                    <button
                      type="button"
                      className="btn btn-default"
                      style={{ marginBottom: "5px" }}
                      onClick={handleClear}
                    >
                      Clear
                    </button>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="btn-group pull-right">
                      <Tools id={"example4"} name={"All Orders"} />
                    </div>
                  </div>
                </div>
                <div className="table-scrollable">
                  <TransactionsTable
                    tableData={tableData}
                    minIndex={minIndex}
                    maxIndex={maxIndex}
                  />
                </div>
                {tableData && tableData.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PaginationComponent
                      pageSize={pageSize}
                      current={current}
                      total={tableData && tableData.length}
                      onChange={handlePagination}
                    />
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
