import React, { Fragment, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { PageTitle } from "../Common/PageTitle";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";
import { Tools } from "../Common/Tools";
import { BreadCrumb } from "../Common/BreadCrumb";
import { SubCategoryUpdateForm } from "./SubCategoryUpdateForm";

export const UpdateSubCategory = (props) => {
  let location = useLocation();
  let categoryParams = location.state;

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Update Subcategory" />
            <BreadCrumb secondPlace="Update Subcategory" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-box">
              <div className="card-head">
                <ComponentHeader title="Update Subcategory" />
                <TableTools />
              </div>
                <SubCategoryUpdateForm categoryParams={categoryParams}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
