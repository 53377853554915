import React, { useState, Fragment, useEffect } from "react";
import {
  addCommission,
  updateCommission,
  getCommissions,
} from "../../api/actions/Transactions";
import swal from "sweetalert";

export const DefaultCommission = (props) => {
  const { handleAllCommissions, existingDefault } = props;
  const [defaultPackage, setDefaultPackage] = useState();
  const [error, setError] = useState(false);
  const [errorObj, setErrorObj] = useState({});

  useEffect(() => {
    getCommissions().then((res) => {
      if (res.data.status == "success") {
        let commissionArr = res?.data?.commissions;
        commissionArr.sort((a, b) => a.commission_type - b.commission_type);
        handleExistingDefault(commissionArr);
      }
    });
  }, []);

  const handleExistingDefault = (data) => {
    let newArr = data?.filter((item) => {
      return item.commission_type == 0;
    });
    setDefaultPackage(newArr?.[0]?.commission);
  };

  const addDefaultCommisson = () => {
    if (!defaultPackage) {
      setErrorObj({ defaultPackage: "Required !" });
    } else {
      let obj = {
        package: "Default",
        commission: defaultPackage,
        commission_type: 0,
      };

      if (existingDefault && existingDefault?._id) {
        updateCommission(obj, existingDefault?._id)
          .then((res) => {
            if (res.data.status == "success") {
              swal({
                title: "Successful!",
                text: "Successfully Updated!",
                type: "success",
                timer: 3000,
              });
              handleAllCommissions();
            } else {
              swal("Something went wrong !!!", "Please try again!", "error");
            }
          })
          .catch((error) => {
            swal("Something went wrong !!!", "Please try again!", "error");
          });
      } else {
        addCommission(obj)
          .then((res) => {
            if (res.data.status == "success") {
              handleAllCommissions();
              swal({
                title: "Successful!",
                text: "Successfully Created!",
                type: "success",
                timer: 3000,
              });
            } else {
              swal("Something went wrong !!!", "Please try again!", "error");
            }
          })
          .catch((error) => {
            swal("Something went wrong !!!", "Please try again!", "error");
          });
      }
    }
  };

  const cancelAction = () => {
    setDefaultPackage();
  };

  return (
    <div className="card-box">
      <div className="card-head">
        <header>Default Commission</header>
      </div>
      <div className="card-body row">
        <div className="col-md-6 p-t-20">
          <label className="mdl-textfield__label" style={{ paddingLeft: '15px'}}>Add Default Commission (KWD)</label>
          <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
            <input
              className="mdl-textfield__input"
              type="number"
              id="txtLasttName"
              value={defaultPackage}
              onChange={(e) => {
                setDefaultPackage(e.target.value);
                setErrorObj({});
              }}
            />
            {errorObj.defaultPackage ? (
              <span style={{ color: "red" }}>Required !</span>
            ) : (
              <Fragment />
            )}
          </div>
        </div>
        <div className="col-lg-12 p-t-20 text-left">
          <button
            type="button"
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
            onClick={addDefaultCommisson}
          >
            Save
          </button>
          <button
            type="button"
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
            onClick={cancelAction}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
