import {
  IS_FETCHING,
  DONE_FETCHING,
  GET_CATEGORY,
  GET_CATEGORIES,
  GET_SUB_CATEGORY,
  GET_SUB_CATEGORIES,
} from "../Actions/ActionTypes";

const initialState = {
  isFetching: false,
  authenticated: null,
  categories: [],
  category: {},
  subCategories: [],
  subCategory: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, isFetching: true };
    case DONE_FETCHING:
      return { ...state, isFetching: false };
    case GET_SUB_CATEGORIES:
      return { ...state, subCategories: action.payload };
    case GET_SUB_CATEGORY:
      return { ...state, subCategory: action.payload };
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case GET_CATEGORY:
      return { ...state, category: action.payload };
    default:
      return state;
  }
}
