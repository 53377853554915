import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { logInAdmin } from "../../api/actions/UserAction";
import { authenticate } from "../../Redux/Actions/Auth";
import { ACCESS_TOKEN } from "../../Redux/Actions/ActionTypes";
import swal from 'sweetalert';

export const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorObj, setErrorObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [isRemembered, setRemember] = useState(false);
  const emailRegex = RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  );

  useEffect(() => {
    if (localStorage.email && localStorage.password && localStorage.remeber) {
      setLogin(localStorage.email);
      setPassword(localStorage.password);
      setRemember(true);
    }
  }, []);

  const handleLogin = () => {
    if (!login || !password) {
      setError(true);
      setErrorMessage("Please Fill Required Fields !!!");
      swal("Please fill required fields !!!", "", "error");
    } else {
      const obj = {
        login,
        password,
      };

      const errors = validateForm(obj);
      // if (!Object.keys(errors).length) {
        logInAdmin(obj)
          .then((res) => {
            if (res.data.token) {
              setLoading(false);
              dispatch(authenticate(true));
              localStorage.setItem(ACCESS_TOKEN, `Bearer ${res.data.token}`);
              if (isRemembered) {
                localStorage.email = login;
                localStorage.password = password;
                localStorage.remeber = true;
              } else {
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                localStorage.removeItem("remeber");
              }
              history.push({
                pathname: "/dashboard",
              });
              swal("Successfully Logged!", "", "success");
            } else {
              setLoading(false);
              dispatch(authenticate(false));
              swal("Something went wrong !!!", "", "error");
            }
          })
          .catch((error) => {
            swal(`${error.response.data.message}`, "", "error");
            setLoading(false);
            // if (e.response && e.response.data && e.response.data.message) {
            //   setError(e.response.data.message);
            // }
          });
      // } else {
      //   setErrorMessage(errors.login)
      //   setLoading(false);
      //   setError(true);
      //   setTimeout(() => {
      //     setErrorMessage('');
      //     setError(false);
      //   }, 4000)
      // }
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.login) {
      errors.login = "Username Required !";
    } else if (!emailRegex.test(data.login)) {
      errors.login = "Invalid Email!";
    } else if (!data.password) {
      errors.password = "Password Required !";
    } 
    return errors;
  };

  return (
    <div className="limiter">
      <div className="container-login100 page-background">
        <div className="wrap-login100">
          <div className="login100-form validate-form">
            <img
              alt=""
              className="login100-form-logo"
              src="assets/img/Admin_Vertical.png"
            />
            <span className="login100-form-title p-b-34 p-t-27">Log in</span>

            <div
              className="wrap-input100 validate-input"
              data-validate="Enter username">
              <input
                className="input100"
                type="text"
                name="username"
                placeholder="Username"
                onChange={(e) => {
                  setLogin(e.target.value);
                  setError(false);
                }}
              />
              <span
                className="focus-input100 text-left"
                data-placeholder="&#xf207;"></span>
            </div>
            <div
              className="wrap-input100 validate-input"
              data-validate="Enter password">
              <input
                className="input100"
                type="password"
                name="pass"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError(false);
                }}
              />
              <span
                className="focus-input100 text-left"
                data-placeholder="&#xf191;"></span>
            </div>
            <div className="contact100-form-checkbox text-left">
              <input
                className="input-checkbox100"
                id="ckb1"
                type="checkbox"
                name="remember-me"
                checked={isRemembered}
                onChange={(e) => setRemember(!isRemembered)}
              />
              <label className="label-checkbox100" for="ckb1">
                Remember me
              </label>
            </div>
            <div className="container-login100-form-btn">
              <button className="login100-form-btn" onClick={handleLogin}>
                Login
              </button>
            </div>
            {error && (
              <div className="error-login">
                <span className="error-text">{errorMessage}</span>
              </div>
            )}

            <div className="text-center p-t-90">
              <a className="txt1" href="/forgetPassword">
                Forgot Password?
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(Login);
