import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import { getOrderDetails } from "../../api/actions/OrderAction";
import { PageTitle } from "../Common/PageTitle";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";
import { Tools } from "../Common/Tools";
import { BreadCrumb } from "../Common/BreadCrumb";
import { OrderedProducts } from "./OrderedProducts";
import { PaginationComponent } from "../Common/Pagination";

const pageSize = 10;

export const ViewOrder = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [otherData, setOtherData] = useState({});
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    getOrderDetails(location.state).then((res) => {
      if (res.data.status == "success") {
        setTableData(res.data.order.products);
        setOtherData(res.data.order);
        setTotalPage(res.data && res?.data?.products?.length / pageSize);
        setMinIndex(0);
        setMaxIndex(pageSize);
      } else {
      }
    });
  }, []);

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Manage Orders" />
            <BreadCrumb secondPlace="Order Management" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-box">
              <div className="card-head">
                <ComponentHeader title="Order Details" />
                <TableTools />
              </div>
              <div className="card-body ">
                <div className="row p-b-20">
                  <div className="col-xl-6 col-md-6 col-12">
                    <div className="info-box bg-blue">
                      <span className="info-box-icon push-bottom">
                        <i className="material-icons">style</i>
                      </span>
                      <div className="info-box-content">
                        <span
                          className="info-box-text"
                          style={{ color: "black" }}
                        >
                          Vendor Details -{" "}
                        </span>
                        <span className="info-box-number">
                          {otherData?.vendor?.fullName}
                        </span>
                        <br />
                        <span className="info-box-number">
                          {otherData?.vendor?.email}
                        </span>
                        <br />
                        <span className="info-box-number">
                          {otherData?.vendor?.phone_number}
                        </span>
                        <div className="progress">
                          <div className="progress-bar width-70"></div>
                        </div>
                      </div>
                      {/* <!-- /.info-box-content --> */}
                    </div>
                    {/* <!-- /.info-box --> */}
                  </div>
                  {/* <!-- /.col --> */}
                  <div className="col-xl-6 col-md-6 col-12">
                    <div className="info-box bg-success">
                      <span className="info-box-icon push-bottom">
                        <i className="material-icons">monetization_on</i>
                      </span>
                      <div className="info-box-content">
                        <span
                          className="info-box-text"
                          style={{ color: "black" }}
                        >
                          Customer Details -
                        </span>
                        <span className="info-box-number">
                          {otherData?.customer?.fullName}
                        </span>
                        <br />
                        <span className="info-box-number">
                          {otherData?.customer?.email}
                        </span>
                        <br />
                        <span className="info-box-number">
                          {otherData?.customer?.phone_number}
                        </span>
                        {/* <span>$</span> */}
                        <div className="progress">
                          <div className="progress-bar width-70"></div>
                        </div>
                      </div>
                      {/* <!-- /.info-box-content --> */}
                    </div>
                    {/* <!-- /.info-box --> */}
                  </div>
                  {/* <!-- /.col --> */}

                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="col-xl-3 col-md-6 col-12"></div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="btn-group pull-right">
                      <Tools />
                    </div>
                  </div>
                </div>
                <div className="table-scrollable">
                  <OrderedProducts
                    tableData={tableData}
                    minIndex={minIndex}
                    maxIndex={maxIndex}
                  />
                </div>
                {tableData && tableData.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PaginationComponent
                      pageSize={pageSize}
                      current={current}
                      total={tableData && tableData.length}
                      onChange={handlePagination}
                    />
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
