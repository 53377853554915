import React from "react";
import { Login } from "../components/Authentication/Login";

class Authentication extends React.Component {
  render() {
    return <Login />;
  }
}

export default Authentication;
