import React, { Fragment } from "react";

export const UserTable = (props) => {
  const { tableData, minIndex, maxIndex } = props;

  return (
    <Fragment>
      <table
        className="table table-hover table-checkable order-column full-width"
        id="example4"
      >
        <thead>
          <tr>
            <th className="center"> #No</th>
            <th className="center"> </th>
            <th className="center"> Name </th>
            <th className="center"> Address </th>
            <th className="center"> Email </th>
            <th className="center"> Phone Number </th>
            <th className="center"> Status </th>
            <th className="center"> Action </th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((item, key) => {
            return (
              <Fragment>
                {key >= minIndex && key < maxIndex && (
                  <tr className="odd gradeX">
                    <td className="center">
                      <span>{key + 1}</span>
                    </td>
                    <td className="center">
                      <span>{item?.fullName}</span>
                    </td>
                    <td className="user-circle-img">
                      <span>
                        <img
                          src={item?.profile_image_url}
                          style={{
                            width: "50px",
                            height: "50px",
                          }}
                        />
                      </span>
                    </td>
                    <td className="center">
                      <span>{item?.addresses[0]}</span>
                    </td>
                    <td className="center">
                      <span>{item?.phone_number}</span>
                    </td>
                    <td className="center">
                      <span>{item?.email}</span>
                    </td>
                    <td className="center">
                      <span>Active</span>
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};
