import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";

export const SubCategoryList = (props) => {
  const { tableData, minIndex, maxIndex, handleDelete } = props;
  let history = useHistory();

  return (
    <table
      className="table table-hover table-checkable order-column full-width"
      id="example4"
    >
      <thead>
        <tr>
          <th className="center"> #No</th>
          <th className="center"> Sub Categories </th>
          <th className="center"> Categories </th>
          <th className="center"> Status </th>
          <th className="center"> Action </th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((item, key) => {
          return (
            <Fragment>
              {key >= minIndex && key < maxIndex && (
                <tr className="odd gradeX" key={key}>
                  <td className="user-circle-img">
                    <span>{key + 1}</span>
                  </td>
                  <td className="center">{item.name}</td>
                  <td className="center">{item.category}</td>
                  <td className="center">Confirmed</td>
                  <td className="center">
                    <Link
                      to={{
                        pathname: "/updateSubCategory",
                        state: {
                          id: item && item._id,
                          data: item,
                        },
                      }}
                      className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                    >
                      <span>Edit</span>
                    </Link>
                    <button
                      type="button"
                      className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
                      onClick={() => handleDelete(item?.category._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
