import React, { useState } from "react";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";

export const PayVendor = (props) => {
  const [paymentDetails, setPaymentDetails] = useState({})
  const handlePay = () => {}

  return (
    <div class="page-content-wrapper">
      <div class="page-content">
        <div class="page-bar">
          <div class="page-title-breadcrumb">
            <div class=" pull-left">
              <div class="page-title">Payment Details</div>
            </div>
            <ol class="breadcrumb page-breadcrumb pull-right">
              <li>
                <i class="fa fa-home"></i>&nbsp;
                <a class="parent-item" href="index.html">
                  Home
                </a>
                &nbsp;<i class="fa fa-angle-right"></i>
              </li>
              <li class="active">Order Management</li>
            </ol>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="card-box">
              <div class="card-head">
                <header>Shop Name</header>
                <button
                  id="panel-button"
                  class="mdl-button mdl-js-button mdl-button--icon pull-right"
                  data-upgraded=",MaterialButton">
                  <i class="material-icons">more_vert</i>
                </button>
                <ul
                  class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                  data-mdl-for="panel-button">
                  <li class="mdl-menu__item">
                    <i class="material-icons">assistant_photo</i>Action
                  </li>
                  <li class="mdl-menu__item">
                    <i class="material-icons">print</i>Another action
                  </li>
                  <li class="mdl-menu__item">
                    <i class="material-icons">favorite</i>Something else here
                  </li>
                </ul>
              </div>
              <div class="card-body row">
                <div class="col-lg-12 p-t-20 text-left">
                  <button
                    type="button"
                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                    onClick={handlePay}
                    >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
