import {
  IS_FETCHING,
  DONE_FETCHING,
  GET_VENDORS,
  GET_VENDOR,
} from "../Actions/ActionTypes";

const initialState = {
  isFetching: false,
  authenticated: null,
  vendors: [],
  vendor: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, isFetching: true };
    case DONE_FETCHING:
      return { ...state, isFetching: false };
    case GET_VENDORS:
      return { ...state, vendors: action.payload };
    case GET_VENDOR:
      return { ...state, vendor: action.payload };
    default:
      return state;
  }
}
