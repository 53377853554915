import React, { Fragment, useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getOrderDetails } from "../../api/actions/OrderAction";
import { DetailsTable } from "./DetailsTable";
import { TableTools } from "../Common/TableTools";
import moment from "moment";
import { PaginationComponent } from "../Common/Pagination";
import { PageTitle } from "../Common/PageTitle";
import { ComponentHeader } from "../Common/ComponentHeader";
import { Tools } from "../Common/Tools";
import { BreadCrumb } from "../Common/BreadCrumb";

const pageSize = 10;

export const TodayTransactionDetails = (props) => {
  let location = useLocation();
  let productParams = location.state;
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
console.log("productParams", productParams);
  useEffect(() => {
    getOrderDetails(productParams).then((res) => {
      console.log("===res===", res.data);
      setTableData(res.data);
    });
  }, []);

  const handlePagination = total => {

  }

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Manage Transactions" />
            <BreadCrumb secondPlace="Transaction Management" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-box">
              <div className="card-head">
                <ComponentHeader title="Today Transaction Details" />
                <TableTools />
              </div>
              <div className="card-body ">
                <div className="row p-b-20">
                  <div className="col-md-6 col-sm-6 col-6"></div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="btn-group pull-right">
                      <Tools id={"example4"} name={"All Orders"} />
                    </div>
                  </div>
                </div>
                <div className="table-scrollable">
                  <DetailsTable
                    tableData={tableData}
                    // minIndex={minIndex}
                    // maxIndex={maxIndex}
                  />
                </div>
                {tableData && tableData.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PaginationComponent
                      pageSize={pageSize}
                      current={current}
                      total={tableData && tableData.length}
                      onChange={handlePagination}
                    />
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
