import React, { Fragment } from "react";
import moment from "moment";

export const DetailsTable = (props) => {
    const { tableData } = props;
console.log("res.data", tableData.order);
    const handleProdcutDetails = data => {
        return (
            data?.map((item, key) => {
                return (
                    <Fragment key={key}>
                        <td></td>
                        <td></td>
                        <td><span>{item.amount}</span></td>
                    </Fragment>
                )
            })
        )
    }

  return (
    <table
      className="table table-hover table-checkable order-column full-width"
      id="example4"
    >
      <thead>
        <tr>
          <th className="center"> Product Id</th>
          <th className="center"> Product Name </th>
          <th className="center"> Unit Price(KWD) </th>
          <th className="center"> Quantity </th>
          <th className="center"> Purchased On </th>
          <th className="center"> Vendor Name </th>
          <th className="center"> Action </th>
        </tr>
      </thead>
      <tbody>
        {/* {tableData?.order?.map((item, key) => {
            return (
                <tr>
                    <td>{key + 1}</td>
                    {handleProdcutDetails(item?.products)}
                    <td><span>{moment(item?.date).format("YYYY-MM-DD")}</span></td>
                    <td>{item?.vendor?.name_of_business}</td>
                    <td>{key + 1}</td>
                </tr>
            )
        })} */}
      </tbody>
    </table>
  );
};
