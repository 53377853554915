import {
    IS_FETCHING,
    DONE_FETCHING,
    GET_ORDER,
    GET_ORDERS,
  } from "../Actions/ActionTypes";

  const initialState = {
    isFetching: false,
    orders: [],
    order: {},
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case IS_FETCHING:
        return { ...state, isFetching: true };
      case DONE_FETCHING:
        return { ...state, isFetching: false };
      case GET_ORDERS:
        return { ...state, orders: action.payload };
      case GET_ORDER:
        return { ...state, order: action.payload };
      default:
        return state;
    }
  }