import axios from "axios";

export const getVendorList = (params = {}) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/admin/vendors`, {
        headers: {
            Authorization: localStorage.access_token
        }
    });
}

export const acceptVendor = (id, data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/admin/vendor/${id}`, data, {
        headers: {
            Authorization: localStorage.access_token
        }
    })
}

export const rejectVendor = data => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/admin/vendor/reject/${data}`, {}, {
        headers: {
            Authorization: localStorage.access_token
        }
    })
}

export const getVendorDetails = (id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/admin/vendor/${id}`, {
        headers: {
            Authorization: localStorage.access_token
        }
    });
}

export const productAccept = (data, id) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/admin/vendor/product/${id}`, data, {
        headers: {
            Authorization: localStorage.access_token
        }
    });
}

export const deleteVendor = (id) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/admin/vendor/${id}`, {
        headers: {
            Authorization: localStorage.access_token
        }
    });
}