import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export const ListProducts = (props) => {
  const { tableData, minIndex, maxIndex } = props;

  return (
    <table
      className="table table-hover table-checkable order-column full-width"
      id="example4"
    >
      <thead>
        <tr>
          <th className="center"> #No</th>
          <th className="center"> </th>
          <th className="center"> Product Name </th>
          <th className="center"> Vendor Name </th>
          <th className="center"> Category </th>
          <th className="center"> Stock </th>
          <th className="center"> Status </th>
          <th className="center"> Action </th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((item, key) => {
          return (
            <Fragment>
              {key >= minIndex && key < maxIndex && (
                <tr className="odd gradeX">
                  <td className="user-circle-img">
                    <span>{key + 1}</span>
                  </td>
                  <td className="user-circle-img">
                    <span>
                      <img
                        src={item.images[0]}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </span>
                  </td>
                  <td className="center">
                    <span>{item && item.name}</span>
                  </td>
                  <td className="user-circle-img">
                    <span>{item.vendor && item.vendor.fullName}</span>
                  </td>
                  <td className="center">
                    <span>{item && item.category && item.category.name}</span>
                  </td>
                  <td className="center">
                    <span>{item && item.stock}</span>
                  </td>
                  <td className="center">
                    <span>{item && item.status}</span>
                  </td>
                  <td className="center">
                    <Link
                      to={{
                        pathname: "/viewProduct",
                        state: item && item._id,
                      }}
                      className="btn deepPink-bgcolor btn-xs"
                    >
                      <span>
                        {item && item.ar_enabled ? "Accept Product" : "View"}
                      </span>
                    </Link>
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
