import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import "react-dropzone-uploader/dist/styles.css";
import {
  addSubCategory,
  editCategory,
  getCategoryList,
} from "../../api/actions/ProductAction";

export const SubCategoryUpdateForm = (props) => {
  const { categoryParams } = props;
  const history = useHistory();
  const [subcategory, setSubCategory] = useState("");
  const [category, setCategory] = useState("");
  const [categoryArr, setCategoryArr] = useState([]);

  useEffect(() => {
    getCategoryList().then((res) => {
      handleCategories(res.data.categories);
    });
  }, []);

  const handleCategories = (arr) => {
    let obj = {};
    let newArr = [];
    arr.forEach((element) => {
      obj = {
        name: element.name,
        value: element._id,
      };
      newArr.push(obj);
    });
    setCategoryArr(newArr);
  };

  const handleSubmit = () => {
    let obj = {};
    if (categoryParams) {
      obj = {
        name: subcategory ? subcategory : null,
      };
      editCategory(categoryParams.id, obj).then((res) => {
        if (res.data.status == "success") {
          history.push({
            pathname: "/subCategories",
          });
          swal("Successfully Confirmed!", "You clicked the button!", "success");
        } else {
          swal("Something went wrong !!!", "You clicked the button!", "error");
        }
      });
    } else {
      if (!category || !subcategory) {
        swal("Please fill required fields !!!", "", "error");
      } else {
        obj = {
          name: subcategory,
        };
        addSubCategory(category, obj).then((res) => {
          if (res.data.status == "success") {
            history.push({
              pathname: "/subCategories",
            });
            swal(
              "Successfully Confirmed!",
              "You clicked the button!",
              "success"
            );
          } else {
            swal(
              "Something went wrong !!!",
              "You clicked the button!",
              "error"
            );
          }
        });
      }
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/subCategories",
    });
  };

  return (
    <div className="card-body ">
      <div className="col-lg-6 p-t-20">
        <label
          for="list2"
          className="mdl-textfield__label"
          style={{ marginLeft: "10px" }}
        >
          Category
        </label>
        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
          <select
            onChange={(e) => setCategory(e.target.value)}
            className="mdl-textfield__input"
          >
            <option />
            {categoryArr.map((item, key) => {
              return (
                <option value={item.value} key={key} className="mdl-menu__item">
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="col-lg-6 p-t-20">
        <label
          for="list2"
          className="mdl-textfield__label"
          style={{ marginLeft: "10px" }}
        >
          Subcategory
        </label>
        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
          <input
            defaultValue={categoryParams ? categoryParams.data.name : undefined}
            className="mdl-textfield__input"
            type="text"
            id="txtLasttName"
            onChange={(e) => setSubCategory(e.target.value)}
          />
        </div>
      </div>
      <div className="col-lg-12 p-t-20 text-left">
        <button
          type="button"
          className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
          onClick={handleSubmit}
        >
          Save
        </button>
        <button
          type="button"
          className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
