import React from "react";

export const NewVendors = (props) => {
    const { tableData } = props;
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <div className="card  card-box">
          <div className="card-head">
            <header>New Vendors</header>
            <div className="tools">
              <a
                className="fa fa-repeat btn-color box-refresh"
                href=""></a>
              <a
                className="t-collapse btn-color fa fa-chevron-down"
                href=""></a>
              <a
                className="t-close btn-color fa fa-times"
                href=""></a>
            </div>
          </div>
          <div className="card-body ">
            <div className="table-wrap">
              <div className="table-responsive">
                <table
                  className="table display product-overview mb-30"
                  id="support_table5">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>About</th>
                      <th>Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                      {tableData && tableData.map((item, key) => {
                          return (
                            <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{item?.fullName}</td>
                            <td>{item?.email}</td>
                            <td>
                              {item?.about}
                            </td>
                            <td>{item?.phone_number}</td>
                          </tr>
                          )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
