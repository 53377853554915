import React from "react";

export const BreadCrumb = (props) => {
  return (
    <ol className="breadcrumb page-breadcrumb pull-right">
      <li>
        <i className="fa fa-home"></i>&nbsp;
        <a className="parent-item" href="index.html">
          Home
        </a>
        &nbsp;<i className="fa fa-angle-right"></i>
      </li>
      <li className="active">{props.secondPlace}</li>
    </ol>
  );
};
