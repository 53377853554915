import React, { useEffect, useState } from "react";
import { PageTitle } from "../Common/PageTitle";
import { ComponentHeader } from "../Common/ComponentHeader";
import { BreadCrumb } from "../Common/BreadCrumb";
import { DefaultCommission } from "./DefaultCommission";
import { CustomPackages } from "./CustomPackages";
import { AddedPackages } from "./AddedPackages";
import { getCommissions } from "../../api/actions/Transactions";

export const CustomCommission = (props) => {
  const [allCommissions, setAllCommissions] = useState([]);
  const [existingDefault, setExistingDefault] = useState({})

  useEffect(() => {
    getAllCommissions();
  }, []);

  const getAllCommissions = () => {
    getCommissions().then((res) => {
      if (res.data.status == "success") {
        let commissionArr = res?.data?.commissions
        commissionArr.sort((a, b) => a.commission_type - b.commission_type)
        handleExistingDefault(commissionArr);
        setAllCommissions(commissionArr);
      }
    });
  };

  const handleExistingDefault = data => {
    let newArr = data?.filter(item => { return item.commission_type == 0});
    setExistingDefault(newArr?.[0]);
  }

  const handleAllCommissions = () => {
    getAllCommissions();
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Commission Configurations" />
            <BreadCrumb secondPlace="Custom Commissions" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card-head">
              <ComponentHeader title="Create Commission Packages" />
            </div>
            <div className="card-body ">
              <DefaultCommission
                handleAllCommissions={handleAllCommissions}
                allCommissions={allCommissions}
                existingDefault={existingDefault && existingDefault}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card-body ">
              <CustomPackages
                handleAllCommissions={handleAllCommissions}
                allCommissions={allCommissions}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card-body ">
              <AddedPackages
                handleAllCommissions={handleAllCommissions}
                allCommissions={allCommissions}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
