import axios from "axios";

export const getProductList = (params = {}) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/products`, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

// export const addCommission = (data) => {
//   return axios.patch(
//     `${process.env.REACT_APP_API_URL}/admin/commission`,
//     data,
//     {
//       headers: {
//         Authorization: localStorage.access_token,
//       },
//     }
//   );
// };

export const getProduct = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/products/${id}`, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const acceptProduct = (id) => {
  return axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/products/${id}`,
    {},
    {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
  );
};

export const addArObject = (id, data) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}/admin/ar/${id}`, data, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const imageDownload = (params = {}) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/image/download`, {
    headers: {},
    params,
  });
};

export const getCategoryList = (params = {}) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/category`,
    {
      headers: {
        Authorization: localStorage.access_token,
      },
    },
    params
  );
};

export const deleteCategory = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/admin/category/${id}`, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const getCategoryDetails = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/category/${id}`, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const addCategory = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/admin/category`, data, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const editCategory = (id, data) => {
  return axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/category/${id}`,
    data,
    {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
  );
};

export const getSubCategoryList = (params = {}) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/subcategory`,
    {
      headers: {
        Authorization: localStorage.access_token,
      },
    },
    params
  );
};

export const deleteSubCategory = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/admin/subCategory/${id}`, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const addSubCategory = (id, data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/admin/subCategory/${id}`, data, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const editSubCategory = (id, data) => {
  return axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/subCategory/${id}`,
    data,
    {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
  );
};