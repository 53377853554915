import React, { Fragment, useEffect, useState } from "react";
import swal from "sweetalert";
import { PageTitle } from "../Common/PageTitle";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";
import { Tools } from "../Common/Tools";
import { BreadCrumb } from "../Common/BreadCrumb";
import { ProfessionalsTable } from "./ProfessionalsTable";
import {
  acceptProfessional,
  getProfessionals,
  rejectProfessional,
} from "../../api/actions/professionalsAction";
import { PaginationComponent } from "../Common/Pagination";

const pageSize = 10;

export const ProfessionalsList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getProfessionals()
      .then((res) => {
        if (res.data.status == "success") {
          setIsLoading(false);
          setTableData(res.data.professionals);
          setTotalPage(res.data && res.data.professionals.length / pageSize);
          setMinIndex(0);
          setMaxIndex(pageSize);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error) {
          setIsLoading(false);
        }
      });
  }, []);

  const handleAccept = (id) => {
    swal({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptProfessional(id).then((res) => {
          if (res.data.status == "success") {
            swal(`Successfully Accepted !`, {
              icon: "success",
            });
            getProfessionals().then((res) => {
              if (res.data.professionals) {
                setTableData(res.data.professionals);
                setTotalPage(
                  res.data && res.data.professionals.length / pageSize
                );
                setMinIndex(0);
                setMaxIndex(pageSize);
              } else {
              }
            });
          } else {
            swal(
              "Something went wrong !!!",
              "You clicked the button!",
              "error"
            );
          }
        });
      } else {
        swal("Process Terminated!");
      }
    });
  };

  const handleReject = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once Updated, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        rejectProfessional(id).then((res) => {
          if (res.data.status == "success") {
            swal(`Successfully Rejected !`, {
              icon: "success",
            });
            getProfessionals().then((res) => {
              if (res.data.professionals) {
                setTableData(res.data.professionals);
                setTotalPage(
                  res.data && res.data.professionals.length / pageSize
                );
                setMinIndex(0);
                setMaxIndex(pageSize);
              } else {
              }
            });
          } else {
            swal(
              "Something went wrong !!!",
              "You clicked the button!",
              "error"
            );
          }
        });
      } else {
        swal("Process Terminated!");
      }
    });
  };

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <Fragment>
      {isLoading && <div id="preloader"></div>}
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <PageTitle title="Manage Professionals" />
              <BreadCrumb secondPlace="Professional Management" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-box">
                <div className="card-head">
                  <ComponentHeader title="" />
                  <TableTools />
                </div>
                <div className="card-body ">
                  <div className="row p-b-20">
                    <div className="col-md-6 col-sm-6 col-6"></div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="btn-group pull-right">
                        <Tools />
                      </div>
                    </div>
                  </div>
                  <div className="table-scrollable">
                    <ProfessionalsTable
                      tableData={tableData}
                      acceptProfessional={handleAccept}
                      rejectProfessional={handleReject}
                      minIndex={minIndex}
                      maxIndex={maxIndex}
                    />
                  </div>
                  {tableData && tableData.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PaginationComponent
                        pageSize={pageSize}
                        current={current}
                        total={tableData && tableData.length}
                        onChange={handlePagination}
                      />
                    </div>
                  ) : (
                    <Fragment />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
