import axios from "axios";

export const logInAdmin = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/admin/login`, data);
};

export const getUserList = (params = {}) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/customers`,
    {
      headers: {
        Authorization: localStorage.access_token,
      },
    },
    params
  );
};

export const forgetPassword = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/admin/forgot`, data);
};

export const sendNotification = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/fire/sendMessage`, data);
};