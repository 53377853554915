import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import Axios from "axios";
import swal from "sweetalert";
import Slider from "react-slick";
import Dropzone from "react-dropzone-uploader";
import { saveAs } from "file-saver";
import { PageTitle } from "../Common/PageTitle";
import {
  acceptProduct,
  getProduct,
  addArObject,
  imageDownload,
} from "../../api/actions/ProductAction";
import { FileUpload } from "./FileUpload";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-dropzone-uploader/dist/styles.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
};

export const ViewProduct = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [product, setProduct] = useState({});
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [arFileList, setArFileList] = useState([]);
  const [arObj, setArObj] = useState("");

  useEffect(() => {
    getProduct(location.state).then((res) => {
      if (res.data.status == "success") {
        setProduct(res.data.product);
      } else {
      }
    });
  }, []);

  const renderProducts = (data) => {
    if (data != undefined) {
      return data.map((item, key) => {
        return (
          <div className="bourse-slider__item slick-slide">
            <div className="bs-item-inner pos-rel">
              <div className="shop-items">
                <img
                  src={item}
                  className="shop-items"
                  style={{ width: "150px", height: "150px" }}
                />
              </div>
            </div>
          </div>
        );
      });
    }
  };

  const handleAccept = (id) => {
    acceptProduct(id).then((res) => {
      if (res.data.status == "success") {
        setMessage("Successfully Submitted !");
        history.push({
          pathname: "/products",
        });
        swal("Successfully Confirmed!", "You clicked the button!", "success");
      } else {
        setError(true);
        setMessage("Something went wrong !!!");
        swal("Something went wrong !!!", "You clicked the button!", "error");
      }
    });
  };

  const handleFileDownLoad = (file) => {
    let imageUrl = file.split("/");
    let obj = {
      url: imageUrl[3],
    };

    imageDownload(obj).then((response) => {
      const url = `${response.config.url}?url=${response.config.params.url}`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.config.params.url);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleChangeStatusAR = ({ meta, file }, status) => {
    if (status == "done") {
      FileUpload(file, (res) => {
        console.log("--- res ---", res);
        console.log(" ----- -----");
        console.log("--- location ---", res.location);
        arFileList.push(res.location);
        handleArObject(arFileList);
      });
    }
  };

  const handleArObject = (data) => {
    let imageExtentions = ".jpeg" || ".jpg" || ".png";
    let arExtenstion = ".scn";

    let x = data.filter(function (file) {
      return file.indexOf(imageExtentions) !== -1;
    });
    let y = data.filter(function (file) {
      return file.indexOf(arExtenstion) !== -1;
    });
    setArFileList(x);
    setArObj(y && y[0]);
  };

  const getUploadParamsAR = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const handleSubmit = (data) => {
    let obj = {
      ar_generated_images: arFileList,
      ar_object: arObj,
    };
    addArObject(data, obj).then((res) => {
      if (res.data.status == "success") {
        setMessage("Successfully Submitted !");
        swal("Successfully Confirmed!", "You clicked the button!", "success");
      } else {
        setError(true);
        setMessage("Something went wrong !!!");
        swal("Something went wrong !!!", "You clicked the button!", "error");
      }
    });
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Product Details" />
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <a className="parent-item" href="index.html">
                  Home
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li>
                <a className="parent-item" href="">
                  Products
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">Product Details</li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="card card-box">
              <div className="card-head">
                <header>{product && product.name}</header>
              </div>
            </div>
          </div>

          <div className="card-body row">
            <div style={{ marginLeft: "30px" }}>
              <Slider {...settings}>{renderProducts(product.images)}</Slider>
            </div>
          </div>

          {product.ar_images?.length > 0 ? (
            <div className="col-lg-12 p-t-20">
              <div className="card card-box">
                <div className="card-head">
                  <div
                    style={{
                      marginLeft: "20px",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Downloadable AR images
                  </div>
                </div>
                <div className="card-body">
                  {product.ar_images?.map((item, index) => {
                    return (
                      <Fragment>
                        <span className="image-container">
                          <img
                            src={item}
                            className="shop-items"
                            style={{ width: "150px", height: "150px" }}
                          />
                          <button
                            className="btn"
                            onClick={() => handleFileDownLoad(item)}
                          >
                            <img
                              src="assets/img/download-circular-button.png"
                              style={{ width: "20px", height: "20px" }}
                            />
                          </button>
                        </span>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <Fragment />
          )}

          <div className="col-lg-12 p-t-20">
            <div className="card card-box">
              <div className="card-body">
                <table
                  className="table table-hover table-checkable order-column full-width"
                  id="example4"
                >
                  <thead>
                    <tr>
                      <th className="center"> Description </th>
                      <th className="center"> Color </th>
                      <th className="center"> Sub Category </th>
                      <th className="center"> Amount </th>
                      <th className="center"> Stock </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td className="center">{product.description}</td>
                    <td className="center">
                      {" "}
                      {product?.colors?.map((color) => {
                        return <spa>{color}</spa>;
                      })}
                    </td>
                    <td className="center">{product.sub_category}</td>
                    <td className="center">{product.amount.toFixed(2)}</td>
                    <td className="center">{product.stock}</td>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {product?.ar_enabled ? (
            <div className="col-lg-12 p-t-20">
              <div className="card card-box">
                <div className="card-head">
                  <label className="control-label col-md-3">
                    <b>Upload AR Object</b>
                  </label>
                </div>
                <div className="card-body">
                  <Dropzone
                    getUploadParams={getUploadParamsAR}
                    onChangeStatus={handleChangeStatusAR}
                    // onSubmit={handleUpload}
                    accept="*"
                    classNames="dropzone dz-message"
                    inputContent="Drop files here or click to upload."
                  />
                </div>
                <div className="col-lg-2 p-t-20 p-b-20">
                  <button
                    className="btn deepPink-bgcolor btn-x"
                    onClick={() => handleSubmit(product._id)}
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <Fragment />
          )}

          {product?.ar_enabled ? (
            <div className="col-lg-2 p-t-20">
              <button
                className="btn deepPink-bgcolor btn-x"
                onClick={() => handleAccept(product._id)}
              >
                <span>Accept Product</span>
              </button>
            </div>
          ) : (
            <Fragment />
          )}
        </div>
      </div>
    </div>
  );
};
