import React from "react";
import { deleteCommission } from "../../api/actions/Transactions";
import swal from "sweetalert";

export const AddedPackages = (props) => {
  const { allCommissions, handleAllCommissions } = props;
  
  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCommission(id).then((res) => {
          if (res.data.status == "success") {
            swal(`Successfully Deleted !`, {
              icon: "success",
            });
            handleAllCommissions();
          } else {
            swal(
              "Something went wrong !!!",
              "Please try again!",
              "error"
            );
          }
        });
      } else {
        swal("Process Terminated!");
      }
    }); 
  }
  return (
    <div className="card-box">
      <div className="card-head">
        <header>Added Packages</header>
      </div>
      <div className="card-body row">
        <div className="table-wrap" style={{ display: 'contents'}}>
          <div className="table-responsive">
            <table
              className="table display product-overview mb-30"
              id="support_table5"
            >
              <thead>
                <tr>
                  <th>No</th>
                  <th>Package</th>
                  <th>Commission (KWD)</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {allCommissions?.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{item.package}</td>
                      <td>{item.commission}</td>
                      <td>
                        {" "}
                        <button
                          className="btn btn-tbl-delete btn-xs"
                          onClick={() => handleDelete(item._id)}
                        >
                          <i className="fa fa-trash-o "></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
