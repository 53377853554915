import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { PageTitle } from "../Common/PageTitle";
import { BreadCrumb } from "../Common/BreadCrumb";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";
import { getVendorList } from "../../api/actions/VendorAction";
import { VendorTable } from "./VendorTable";
import { PaginationComponent } from "../Common/Pagination";

const pageSize = 10;

export const RegisteredVendorList = (props) => {
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    getVendorList().then((res) => {
      if (res.data.vendors) {
        setTableData(res.data.vendors);
        setTotalPage(res.data && res.data.vendors.length / pageSize);
        setMinIndex(0);
        setMaxIndex(pageSize);
      } else {
        setError(true);
      }
    });
  }, []);

  const recallTableData = () => {
    getVendorList().then((res) => {
      if (res.data.vendors) {
        setTableData(res.data.vendors);
        setTotalPage(res.data && res.data.vendors.length / pageSize);
        setMinIndex(0);
        setMaxIndex(pageSize);
      } else {
        setError(true);
      }
    });
  };

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Dashboard" />
            <BreadCrumb secondPlace="Dashboard" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="card  card-box">
              <div className="card-head">
                <ComponentHeader title="Registered Vendors" />
                <TableTools />
              </div>
              <div className="card-body ">
                <VendorTable
                  tableData={tableData}
                  reRenderData={recallTableData}
                  minIndex={minIndex}
                  maxIndex={maxIndex}
                />
              </div>
              {tableData && tableData.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PaginationComponent
                    pageSize={pageSize}
                    current={current}
                    total={tableData && tableData.length}
                    onChange={handlePagination}
                  />
                </div>
              ) : (
                <Fragment />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
