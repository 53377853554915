import React, { useState } from "react";
import { useHistory } from "react-router";
import Dropzone from "react-dropzone-uploader";
import swal from "sweetalert";
import { FileUpload } from "../ProductManagement/FileUpload";
import "react-dropzone-uploader/dist/styles.css";
import { addCategory, editCategory } from "../../api/actions/ProductAction";

export const CategoryUpdateForm = (props) => {
  const { categoryParams } = props;
  const history = useHistory();
  const [category, setCategory] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");

  const handleSubmit = () => {
    let obj = {};
    if (categoryParams) {
      obj = {
        name: category ? category : null,
        image: uploadedImage ? uploadedImage : null,
      };
      editCategory(categoryParams.id, obj).then((res) => {
        if (res.data.status == "success") {
          history.push({
            pathname: "/viewCategories",
          });
          swal("Successfully Confirmed!", "You clicked the button!", "success");
        } else {
          swal("Something went wrong !!!", "You clicked the button!", "error");
        }
      });
    } else {
      if (!category || !uploadedImage) {
        swal("Please fill required fields !!!", "", "error");
      } else {
        obj = {
          name: category,
          image: uploadedImage,
        };
        addCategory(obj).then((res) => {
          if (res.data.status == "success") {
            history.push({
              pathname: "/viewCategories",
            });
            swal(
              "Successfully Confirmed!",
              "You clicked the button!",
              "success"
            );
          } else {
            swal(
              "Something went wrong !!!",
              "You clicked the button!",
              "error"
            );
          }
        });
      }
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/viewCategories",
    });
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    let arFileList = [];
    if (status == "done") {
      FileUpload(file, (res) => {
        arFileList.push(res.location);
        setUploadedImage(arFileList[0]);
      });
    }
  };

  return (
    <div className="card-body ">
      <div className="col-lg-6 p-t-20">
        <label
          for="list2"
          className="mdl-textfield__label"
          style={{ marginLeft: "10px" }}
        >
          Category
        </label>
        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
          <input
            defaultValue={categoryParams ? categoryParams.data.name : undefined}
            className="mdl-textfield__input"
            type="text"
            id="txtLasttName"
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>
      </div>
      <div className="col-lg-12 p-t-20">
        <div className="card card-box">
          <div className="card-head">
            <label className="control-label col-md-3">
              <b>Upload Image</b>
            </label>
          </div>
          <div className="card-body">
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              // onSubmit={handleUpload}
              accept="*"
              classNames="dropzone dz-message"
              inputContent="Drop files here or click to upload."
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 p-t-20 text-left">
        <button
          type="button"
          className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
          onClick={handleSubmit}
        >
          Save
        </button>
        <button
          type="button"
          className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
