import { combineReducers } from "redux";
import auth from "./Reducers/AuthReducer";
import vendor from "./Reducers/VendorReducer";
import product from "./Reducers/ProductReducer";
import category from "./Reducers/CategoryReducer";
import professional from "./Reducers/ProfessionalReducer";
import order from "./Reducers/OrderReducer";

const reducers = {
  auth,
  vendor,
  product,
  category,
  professional,
  order
};

export default combineReducers(reducers);
