import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { PageTitle } from "../Common/PageTitle";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";
import { Tools } from "../Common/Tools";
import { BreadCrumb } from "../Common/BreadCrumb";
import { filteredOrders, getOrderList } from "../../api/actions/OrderAction";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { PaginationComponent } from "../Common/Pagination";
import { TransactionsTable } from "./TransactionsTable";
import { getVendorList } from "../../api/actions/VendorAction";
import { VendorTransactionTable } from "./VendorTransactionTable";

const pageSize = 10;

export const VendorsTransactions = (props) => {
  const [tableData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();
  const [error, setError] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    getOrders();
    getVendorList().then((res) => {
      vendorArray(res?.data?.vendors);
    });
  }, []);

  const vendorArray = (data) => {
    let vendorArr = [];
    data.forEach((element) => {
      let obj = {
        key: element._id,
        value: element.name_of_business,
      };
      vendorArr.push(obj);
    });
    setVendors(vendorArr);
  };

  const getOrders = (obj) => {
    getOrderList(obj).then((res) => {
      if (res.data.orders) {
        setTableData(res.data.orders);
        setTotalPage(res.data && res.data.orders.length / pageSize);
        setMinIndex(0);
        setMaxIndex(pageSize);
      } else {
        setError(true);
      }
    });
  };

  const getFilteredList = (obj) => {
    filteredOrders(obj).then((res) => {
      if (res.data.orders) {
        setTableData(res.data.orders);
        setTotalPage(res.data && res.data.orders.length / pageSize);
        setMinIndex(0);
        setMaxIndex(pageSize);
      } else {
        setError(true);
      }
    });
  };

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const handleFilter = () => {};

  const handleClear = () => {
    setSelectedVendor("");
  };

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Manage Transactions" />
            <BreadCrumb secondPlace="Transaction Management" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-box">
              <div className="card-head">
                <ComponentHeader title="Vendor's Transactions" />
                <TableTools />
              </div>
              <div className="card-body ">
                <div className="row p-b-20">
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="row">
                      <div className="col-md-4 col-sm-4 col-4">
                        <select
                          onChange={(e) => setSelectedVendor(e.target.value)}
                          className="mdl-textfield__input"
                          value={selectedVendor}
                        >
                          <option />
                          {vendors.map((item, key) => {
                            return (
                              <option
                                value={item._id}
                                key={key}
                                className="mdl-menu__item"
                              >
                                {item.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <button
                        type="button"
                        className="btn deepPink-bgcolor"
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          marginBottom: "5px",
                        }}
                        onClick={handleFilter}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default"
                        style={{ marginBottom: "5px" }}
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="btn-group pull-right">
                      <Tools id={"example4"} name={"All Orders"} />
                    </div>
                  </div>
                </div>
                {/* <div className="table-scrollable">
                  <TransactionsTable
                    tableData={tableData}
                    minIndex={minIndex}
                    maxIndex={maxIndex}
                  />
                </div>
                {tableData && tableData.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PaginationComponent
                      pageSize={pageSize}
                      current={current}
                      total={tableData && tableData.length}
                      onChange={handlePagination}
                    />
                  </div>
                ) : (
                  <Fragment />
                )} */}
                <div className="state-overview">
                  <div className="row">
                    <div className="col-xl-3 col-md-6 col-12">
                      <div className="info-box bg-blue">
                        <span className="info-box-icon push-bottom">
                          <i className="material-icons">style</i>
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text">
                            Total Orders Placed
                          </span>
                          <span className="info-box-number">0</span>
                          <div className="progress">
                            <div className="progress-bar width-60"></div>
                          </div>
                        </div>
                        {/* <!-- /.info-box-content --> */}
                      </div>
                      {/* <!-- /.info-box --> */}
                    </div>
                    <div className="col-xl-3 col-md-6 col-12">
                      <div className="info-box bg-success">
                        <span className="info-box-icon push-bottom">
                          <i className="material-icons">monetization_on</i>
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text">
                            Today Orders Count
                          </span>
                          <span className="info-box-number">0</span>
                          {/* <span>$</span> */}
                          <div className="progress">
                            <div className="progress-bar width-60"></div>
                          </div>
                        </div>
                        {/* <!-- /.info-box-content --> */}
                      </div>
                      {/* <!-- /.info-box --> */}
                    </div>
                    <div className="col-xl-3 col-md-6 col-12">
                      <div className="info-box bg-orange">
                        <span className="info-box-icon push-bottom">
                          <i className="material-icons">card_travel</i>
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text">
                            Registered Vendors for the Day
                          </span>
                          <span className="info-box-number">0</span>
                          {/* <span>$</span> */}
                          <div className="progress">
                            <div className="progress-bar width-40"></div>
                          </div>
                        </div>
                        {/* <!-- /.info-box-content --> */}
                      </div>
                      {/* <!-- /.info-box --> */}
                    </div>
                  </div>
                </div>

                <VendorTransactionTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
