import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export const VendorTransactionTable = (props) => {
  const { tableData, minIndex, maxIndex } = props;

  return (
    <table
      className="table table-hover table-checkable order-column full-width"
      id="example4"
    >
      <thead>
        <tr>
          <th className="center"> Transaction Id</th>
          <th className="center"> Product </th>
          <th className="center"> Purchased On </th>
          <th className="center"> Product Price(KWD) </th>
          {/* <th className="center"> Commission(KWD) </th> */}
          {/* <th className="center"> Vendor Payment(KWD) </th> */}
          <th className="center"> Payment Status </th>
          {/* <th className="center"> Vendor Payment Status </th> */}
          <th className="center"> Action </th>
        </tr>
      </thead>
      <tbody>
        {tableData?.docs?.map((item, key) => {
          return (
            <Fragment>
              {key >= minIndex && key < maxIndex && (
                <tr className="odd gradeX">
                  {/* <td className="user-circle-img">
                    <span>{key + 1}</span>
                  </td>
                  <td className="center">{item.vendor.fullName}</td>
                  <td className="user-circle-img">
                    <span>{moment(item?.date).format("YYYY-MM-DD")}</span>
                  </td>
                  <td className="center">
                    <span>{item.amount}</span>
                  </td>
                  <td className="center">
                    <span>10</span>
                  </td>
                  <td className="center">
                    <span>{item.amount - item.customer.cart.amount}</span>
                  </td>
                  <td className="center">
                    <span>{item.status}</span>
                  </td> */}
                  {/* <td className="center">
                <Link
                  to={{
                    pathname: "/vendorPayment",
                    state: item._id,
                  }}
                  className="btn deepPink-bgcolor btn-xs">
                  <span>PAY NOW</span>
                </Link>
              </td> */}
                  {/* <td className="center">
                    <Link
                      to={{
                        pathname: "/orderDetail",
                        state: item._id,
                      }}
                      className=""
                    >
                      <span>View</span>
                    </Link>
                  </td> */}
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
