//------------ Common --------------------//
export const ACCESS_TOKEN = "access_token";

export const IS_FETCHING = "IS_FETCHING";
export const AUTHENTICATED = "AUTHENTICATED";
export const DONE_FETCHING = "DONE_FETCHING";


//----------- Auth ---------------------//


//----------- Vendor ------------------//
export const GET_VENDORS = "GET_VENDORS";
export const GET_VENDOR = "GET_VENDOR";


//---------- Product ------------------//
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";


//---------- Category ----------------//
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";
export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";

//---------- Professional ----------------//
export const GET_PROFESSIONAL = "GET_PROFESSIONAL";
export const GET_PROFESSIONALS = "GET_PROFESSIONALS";

//---------- Order ----------------//
export const GET_ORDER = "GET_ORDER";
export const GET_ORDERS = "GET_ORDERS";