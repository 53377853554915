import axios from "axios";

export const getCommissions = (params = {}) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/commission`, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const addCommission = (data) => {
  console.log("create commission", data);
  return axios.post(`${process.env.REACT_APP_API_URL}/admin/commission`, data, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const updateCommission = (data, id) => {
  return axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/commission/${id}`,
    data,
    {
      headers: {
        Authorization: localStorage.access_token,
      }
    }
  );
};

export const deleteCommission = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/admin/commission/${id}`, {
    headers: {
      Authorization: localStorage.access_token,
    }
  });
};

export const getCommissionById = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/commission/${id}`, {
    headers: {
      Authorization: localStorage.access_token,
    }
  });
};
