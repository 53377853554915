import React from "react";
import NavigationPanel from "../components/NavigationPanel";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import { UserList } from "../components/UserManagement/UserList";

class UserManagement extends React.Component {
  render() {
    return (
      <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white dark-sidebar-color logo-dark">
        <div className="page-wrapper">
          <NavigationPanel></NavigationPanel>
          <div className="page-container">
            <SideBar activeMenu="1.0"></SideBar>
            <UserList />
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default UserManagement;
