import React, { useEffect, useState } from "react";
import { getOrderList } from "../../api/actions/OrderAction";
import moment from "moment";

export const LastestOrderDetailsComponent = (props) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getOrderList().then((res) => {
      if (res.data.status == "success") {
        handleLatestOrders(res.data.orders.docs);
      } else {
      }
    });
  }, []);

  const handleLatestOrders = (data) => {
    let newArr = [];
    data.forEach((element) => {
      if (element.status == "Pending") {
        let obj = {
          address: element.address,
          amount: element.amount,
          credit_card: element.credit_card,
          customer: element.customer,
          date: element.date,
          products: element.products,
          status: element.status,
          vendor: element.vendor,
        };
        newArr.push(obj);
      }
    });
    newArr.sort((a, b) => new Date(b.date) - new Date(a.date));
    setTableData(newArr);
  };

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <div className="card  card-box">
          <div className="card-head">
            <header>Latest Orders</header>
            <div className="tools">
              <a className="fa fa-repeat btn-color box-refresh" href=""></a>
              <a
                className="t-collapse btn-color fa fa-chevron-down"
                href=""
              ></a>
              <a className="t-close btn-color fa fa-times" href=""></a>
            </div>
          </div>
          <div className="card-body ">
            <div className="table-wrap">
              <div className="table-responsive">
                <table
                  className="table display product-overview mb-30"
                  id="support_table5"
                >
                  <thead>
                    <tr>
                      <th className="center"> Order Id</th>
                      <th className="center"> Vendor Name </th>
                      <th className="center"> Purchased On </th>
                      <th className="center"> Product Price(KWD) </th>
                      <th className="center"> Commission(KWD) </th>
                      <th className="center"> Vendor Payment(KWD) </th>
                      <th className="center"> Order Status </th>
                      {/* <th className="center"> Vendor Payment Status </th> */}
                      {/* <th className="center"> Action </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item, key) => {
                      return (
                        <tr className="odd gradeX" key={key}>
                          <td className="user-circle-img">
                            <span>{key + 1}</span>
                          </td>
                          <td className="center">{item?.vendor?.fullName}</td>
                          <td className="center">
                            <span>
                              {moment(item?.date).format("YYYY-MM-DD")}
                            </span>
                          </td>
                          <td className="center">
                            <span>{item?.amount}</span>
                          </td>
                          <td className="center">
                            <span>10</span>
                          </td>
                          <td className="center">
                            <span>
                              {item?.amount - item?.customer?.cart.amount}
                            </span>
                          </td>
                          <td className="center">
                            <span className="label label-sm label-warning">
                              Pending
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
