import axios from "axios";

export const getOrderList = (params = {}) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/orders`, {
    headers: {
      Authorization: localStorage.access_token,
    },
    params,
  });
};

export const getOrderDetails = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/order/${id}`, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const filteredOrders = (params = {}) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/admin/orders/filterSd`, {
        headers: {
          Authorization: localStorage.access_token,
        },
        params,
      });
}