import React, { useEffect, useState, Fragment } from "react";
import { ListProducts } from "./ListProducts";
import { PageTitle } from "../Common/PageTitle";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";
import { Tools } from "../Common/Tools";
import { BreadCrumb } from "../Common/BreadCrumb";
import { getProductList } from "../../api/actions/ProductAction";
import { PaginationComponent } from "../Common/Pagination";

const pageSize = 10;

export const Products = (props) => {
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    getProductList().then((res) => {
      if (res.data.status == "success") {
        handleTabledata(res.data.products);
      } else {
        setError(true);
      }
    });
  }, []);

  const handleTabledata = (arr) => {
    let newArr = [];
    arr.forEach((element) => {
      if (!element.accepted) {
        newArr.push(element);
      }
    });
    setTableData(newArr);
    setTotalPage(newArr && newArr.length / pageSize);
    setMinIndex(0);
    setMaxIndex(pageSize);
  };

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Manage Products" />
            <BreadCrumb secondPlace="Products Management" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-box">
              <div className="card-head">
                <ComponentHeader title="Pending Products" />
                <TableTools />
              </div>
              <div className="card-body ">
                <div className="row p-b-20">
                  <div className="col-md-6 col-sm-6 col-6"></div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="btn-group pull-right">
                      <Tools />
                    </div>
                  </div>
                </div>
                <div className="table-scrollable">
                  <ListProducts
                    tableData={tableData}
                    minIndex={minIndex}
                    maxIndex={maxIndex}
                  />
                </div>
                {tableData && tableData.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PaginationComponent
                      pageSize={pageSize}
                      current={current}
                      total={tableData && tableData.length}
                      onChange={handlePagination}
                    />
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
