import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import rootReducer from "./Redux/index";
import reportWebVitals from "./reportWebVitals";
import DashBoard from "./pages/DashBoard";
import OrderManagement from "./pages/OrderManagement";
import ManageProduct from "./components/ProductManagement/ManageProduct";
import NewVendor from "./pages/NewVendor";
import PaymentPage from "./pages/PaymentPage";
import Settings from "./pages/Settings";
import UserProfile from "./pages/UserProfile";
import EmailInbox from "./pages/EmailInbox";
import Authentication from "./pages/Authentication";
import VendorPayment from "./pages/VendorPayment";
import VendorProducts from "./pages/VendorProducts";
import ProductManagement from "./pages/ProductManagement";
import ViewProducts from "./pages/ViewProducts";
import RegisteredVendors from "./pages/RegisteredVendors";
import OrderDetailManagement from "./pages/OrderDetailManagement";
import ProfessionalsManagement from "./pages/ProfessionalsManagement";
import CategoryManagement from "./pages/CategoryManagement";
import UserManagement from "./pages/UserManagement";
import UpdateCategoryManagement from "./pages/UpdateCategoryManagement";
import SubCategoryManagement from "./pages/SubCategoryManagement";
import App from "./App";
import "./index.css";
import UpdateSubCategoryManagement from "./pages/UpdateSubCategoryManagement";
import ForgetPassword from "./pages/ForgetPassword";
import CustomCommisionManagement from "./pages/CustomCommissionManagement";
import TodayTransactionManagement from "./pages/TodayTransactionManagement";
import VendorTransactionManagement from "./pages/VendorTransactionManagement";
import TodayDetailManagement from "./pages/TodayDetailManagement";
import NotificationPage from "./pages/NotificationPage";

export const store = createStore(rootReducer, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <App>
      <Router>
        <Switch>
          <React.Fragment>
            <Route exact path="/" component={Authentication} />
            <Route path="/dashboard" component={DashBoard} />
            <Route path="/vendorList" component={RegisteredVendors} />
            <Route path="/order" component={OrderManagement} />
            <Route path="/newVendor" component={NewVendor} />
            <Route path="/manageProduct" component={ManageProduct} />
            <Route path="/payment" component={PaymentPage} />
            <Route path="/settings" component={Settings} />
            <Route path="/userProfile" component={UserProfile} />
            <Route path="/emailInbox" component={EmailInbox} />
            <Route path="/vendorPayment" component={VendorPayment} />
            <Route path="/vendorProducts" component={VendorProducts} />
            <Route path="/products" component={ProductManagement} />
            <Route path="/viewProduct" component={ViewProducts} />
            <Route path="/orderDetail" component={OrderDetailManagement} />
            <Route path="/professionals" component={ProfessionalsManagement} />
            <Route path="/viewCategories" component={CategoryManagement} />
            <Route path="/user" component={UserManagement} />
            <Route path="/updateCategory" component={UpdateCategoryManagement} />
            <Route path="/subCategories" component={SubCategoryManagement} />
            <Route path="/updateSubCategory" component={UpdateSubCategoryManagement} />
            <Route path="/forgetPassword" component={ForgetPassword} />
            <Route path="/customCommission" component={CustomCommisionManagement} />
            <Route path="/todayTransactions" component={TodayTransactionManagement} />
            <Route path="/vendorTransactions" component={VendorTransactionManagement} />
            <Route path="/todayDetails" component={TodayDetailManagement} />
            <Route path="/notification" component={NotificationPage} />
          </React.Fragment>
        </Switch>
      </Router>
    </App>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
