import React from "react";
import { ForgetPasswordComponent } from "../components/Authentication/ForgetPasswordComponent";

class ForgetPassword extends React.Component {
  render() {
    return <ForgetPasswordComponent />;
  }
}

export default ForgetPassword;
