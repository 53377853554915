import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import { PageTitle } from "../Common/PageTitle";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";
import { BreadCrumb } from "../Common/BreadCrumb";
import { sendNotification } from "../../api/actions/UserAction";
import swal from 'sweetalert';

const pageSize = 10;

export const AddNotifications = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [errorObj, setErrorObj] = useState({});

  useEffect(() => {}, []);

  const handleAdd = () => {
    let data = {title: title, msg: description};

    sendNotification(data).then((res) => {
      if (res.data.status === 'success') {
        swal(`${res.data.message}`, "", "success");
        setTitle('');
        setDescription('');
      }
    }).catch((error) => {
            swal(`${error.data.message}`, "", "error");
    });
  }

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Manage Notifications" />
            <BreadCrumb secondPlace="Notification Management" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-box">
              <div className="card-head">
                <ComponentHeader title="Add Notification" />
                <TableTools />
              </div>
              <div className="card-body ">
                <div className="row p-b-20">
                  <div className="col-xl-6 col-md-6 col-12">
                    {/* <!-- /.info-box --> */}
                  </div>
                  {/* <!-- /.col --> */}

                  {/* <!-- /.col --> */}

                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="col-xl-3 col-md-6 col-12"></div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <input
                        className="mdl-textfield__input"
                        type="text"
                        id="txtLasttName"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setErrorObj({});
                        }}
                      />
                      <label className="mdl-textfield__label">Title</label>
                      {errorObj.title ? (
                        <span style={{ color: "red" }}>Required !</span>
                      ) : (
                        <Fragment />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <textarea
                        className="mdl-textfield__input"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                          setErrorObj({});
                        }}
                      />
                      <label className="mdl-textfield__label">
                        Description
                      </label>
                      {errorObj.description ? (
                        <span style={{ color: "red" }}>Required !</span>
                      ) : (
                        <Fragment />
                      )}
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="col-lg-12 p-t-20 text-left">
                      <button
                        type="button"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                        onClick={handleAdd}
                      >
                        Add Notification
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
