import {
    IS_FETCHING,
    DONE_FETCHING,
    GET_PRODUCT,
    GET_PRODUCTS,
  } from "../Actions/ActionTypes";

  const initialState = {
    isFetching: false,
    products: [],
    product: {},
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case IS_FETCHING:
        return { ...state, isFetching: true };
      case DONE_FETCHING:
        return { ...state, isFetching: false };
      case GET_PRODUCTS:
        return { ...state, products: action.payload };
      case GET_PRODUCT:
        return { ...state, product: action.payload };
      default:
        return state;
    }
  }