import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getProductList } from "../../api/actions/ProductAction";

export const LatestProducts = (props) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getProductList().then((res) => {
      if (res.data.status == "success") {
        handleTabledata(res.data.products);
      } else {
      }
    });
  }, []);

  const handleTabledata = (arr) => {
    let newArr = [];
    arr.forEach((element) => {
      if (!element.accepted) {
        newArr.push(element);
      }
    });
    setTableData(newArr);
  };

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <div className="card  card-box">
          <div className="card-head">
            <header>Latest Products</header>
            <div className="tools">
              <a className="fa fa-repeat btn-color box-refresh" href=""></a>
              <a
                className="t-collapse btn-color fa fa-chevron-down"
                href=""
              ></a>
              <a className="t-close btn-color fa fa-times" href=""></a>
            </div>
          </div>
          <div className="card-body ">
            <div className="table-wrap">
              <div className="table-responsive">
                <table
                  className="table display product-overview mb-30"
                  id="support_table5"
                >
                  <thead>
                    <tr>
                      <th className="center"> #No</th>
                      <th className="center"> </th>
                      <th className="center"> Product Name </th>
                      <th className="center"> Vendor Name </th>
                      <th className="center"> Category </th>
                      <th className="center"> Stock </th>
                      <th className="center"> Status </th>
                      <th className="center"> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item, key) => {
                      return (
                        <tr className="odd gradeX" key={key}>
                          <td className="user-circle-img">
                            <span>{key + 1}</span>
                          </td>
                          <td className="user-circle-img">
                            <span>
                              <img
                                src={item.images[0]}
                                style={{ width: "50px", height: "50px" }}
                              />
                            </span>
                          </td>
                          <td className="center">
                            <span>{item && item.name}</span>
                          </td>
                          <td className="center">
                            <span>{item.vendor && item.vendor.fullName}</span>
                          </td>
                          <td className="center">
                            <span>
                              {item && item.category && item.category.name}
                            </span>
                          </td>
                          <td className="center">
                            <span>{item && item.stock}</span>
                          </td>
                          <td className="center">
                            <span>{item && item.status}</span>
                          </td>
                          <td className="center">
                            <Link
                              to={{
                                pathname: "/viewProduct",
                                state: item && item._id,
                              }}
                              className="btn deepPink-bgcolor btn-xs"
                            >
                              <span>
                                {item && item.ar_enabled
                                  ? "Accept Product"
                                  : "View"}
                              </span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
