import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import { Checkbox, Switch } from "antd";
import { PageTitle } from "../Common/PageTitle";
import {
  getVendorDetails,
  productAccept,
} from "../../api/actions/VendorAction";
import "antd/lib/checkbox/style/index.css";
import "antd/dist/antd.css";
import { getCommissions } from "../../api/actions/Transactions";

export const ManageVendorProducts = (props) => {
  let location = useLocation();
  const history = useHistory();
  const [vendor, setVendor] = useState({});
  const [productsN, setProductsN] = useState([]);
  const [productsF, setProductsF] = useState([]);
  const [message, setMessage] = useState("");
  const [checkedF, setFeatured] = useState([]);
  const [checkedN, setNormal] = useState([]);
  const [disablePage, setDisablePage] = useState(true);
  const [commission, setCommission] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [packages, setPackages] = useState([]);
  let productParams = location.state;

  useEffect(() => {
    getVendorDetails(productParams._id).then((res) => {
      if (res.data.status == "success") {
        setVendor(res.data.vendors);
        setSelectedPackage(res?.data?.vendors?.commission);
        handleProducts(res.data.products);
      } else {
        setMessage("Something went wrong !");
      }
    });

    getCommissions().then((res) => {
      if (res.data.status == "success") {
        let commissionArr = res?.data?.commissions
        commissionArr.sort((a, b) => a.commission_type - b.commission_type)
        setPackages(commissionArr);
      }
    });
  }, []);

  const handleFeaturedCheck = (checkedValues) => {
    setFeatured(checkedValues);
  };

  const handleNormalCheck = (checkedValues) => {
    setNormal(checkedValues);
  };

  const handleProducts = (arr) => {
    let normalProducts = [];
    let featuredProducts = [];
    let obj = {};
    arr.forEach((element) => {
      if (element.status == "Featured") {
        obj = {
          value: element._id,
          label: element.name,
        };
        featuredProducts.push(obj);
      } else {
        obj = {
          value: element._id,
          label: element.name,
        };
        normalProducts.push(obj);
      }
    });
    setProductsF(featuredProducts);
    setProductsN(normalProducts);
  };
  const handleSave = () => {
    let obj = {
      disablePage: disablePage,
      normal: checkedN,
      featured: checkedF,
      commissionId: selectedPackage,
    };
    productAccept(obj, vendor._id).then((res) => {
      if (res.data.status == "success") {
        setMessage("Successfully Submitted !");
        history.push({
          pathname: "/vendorList",
        });
      } else {
        setMessage("Something went wrong !");
      }
    });
  };

  const handleDisable = (checked) => {
    setDisablePage(checked);
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <PageTitle title="Manage Vendors" />
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <a className="parent-item" href="index.html">
                  Home
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li>
                <a className="parent-item" href="">
                  Vendor Configurations
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">Manage Registered Vendors</li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="card card-box">
              <div className="card-head">
                <header>{vendor && vendor.name_of_business}</header>
                <div className="tools">
                  <a
                    className="fa fa-repeat btn-color box-refresh"
                    href="javascript:;"
                  ></a>
                  <a
                    className="t-collapse btn-color fa fa-chevron-down"
                    href="javascript:;"
                  ></a>
                  <a
                    className="t-close btn-color fa fa-times"
                    href="javascript:;"
                  ></a>
                </div>
              </div>
              <div className="card-body row">
                <div className="col-lg-2 p-t-20 text-left">Disable page</div>
                <div className="col-lg-4 p-t-20 text-left">
                  <Switch
                    defaultChecked
                    onChange={handleDisable}
                    className="switchToggle"
                  />
                </div>
                <div className="col-lg-6 p-t-20"></div>

                <div className="col-lg-12 p-t-20">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6 text-left">
                        <label>Select Products to Accept</label>
                      </div>
                      <div className="col-lg-6 text-right">
                        <a href="">View Details</a>
                      </div>
                    </div>
                    <Checkbox.Group
                      options={productsN}
                      onChange={handleNormalCheck}
                    />
                  </div>
                </div>

                <div className="col-lg-12 p-t-20">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6 text-left">
                        <label>Featured Products</label>
                      </div>
                      <div className="col-lg-6 text-right">
                        <a href="">View Details</a>
                      </div>
                    </div>
                    <Checkbox.Group
                      options={productsF}
                      onChange={handleFeaturedCheck}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-1 p-t-20 text-left">
                  Commission Package
                  <span>
                    <select
                      value={selectedPackage}
                      onChange={(e) => setSelectedPackage(e.target.value)}
                      className="mdl-textfield__input"
                    >
                      <option value="">Select a commission package </option>
                      {packages?.map((item, key) => {
                        return (
                          <option
                            value={item._id}
                            key={key}
                            className="mdl-menu__item"
                          >
                            {item.package} | {item.commission} (KWD)
                          </option>
                        );
                      })}
                    </select>
                  </span>
                </div>
                <div className="col-lg-6 p-t-20"></div>

                <div className="col-lg-12 p-t-20 text-left">
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
