import React, { useState, useEffect, Fragment } from "react";
import { Switch } from 'antd';
import { getVendorList } from '../../api/actions/VendorAction';

export const VendorCard = (props) => {
  const [tableData, setTableData] = useState([]);
  const [switchState, setSwitchState] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    getVendorList().then((res) => {
      if (res.data.vendors) {
        setTableData(res.data.vendors);
      } else {
        setError(true);
      }
    });
  }, []);

  const handleDisable = (index, event, checked) => {
    console.log("index", index, "event", event, "checked", checked);
    let list = Object.assign({}, switchState);
    list['switch-' + index] = event;
    setSwitchState(list);
  }

  const handleSave = id => {
    //----- API clarification needed ----//
  }

console.log("switchState", switchState);
  return (
    <Fragment>
      {tableData.map((item, key) => {
        return (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card card-box">
                <div className="card-head">
                  <header>{item.name_of_business}</header>
                  <div className="tools">
                    <a
                      className="fa fa-repeat btn-color box-refresh"
                      href="javascript:;"></a>
                    <a
                      className="t-collapse btn-color fa fa-chevron-down"
                      href="javascript:;"></a>
                    <a
                      className="t-close btn-color fa fa-times"
                      href="javascript:;"></a>
                  </div>
                </div>
                <div className="card-body row">
                  <div className="col-lg-2 p-t-20 text-left">Disable page</div>
                  <div className="col-lg-4 p-t-20 text-left">
                    <label className="switchToggle">
                      <input type="checkbox" checked={switchState['switch-' + key]} onChange={(e, checked) => handleDisable(key, item._id, checked)} />
                      <span className="slider green round"></span>
                    </label>
                  </div>
                  <div className="col-lg-6 p-t-20"></div>

                  <div className="col-lg-12 p-t-20">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-lg-6 text-left">
                          <label>Select Products to Accept</label>
                        </div>
                        <div className="col-lg-6 text-right">
                          <a href="">View Details</a>
                        </div>
                      </div>
                      <div className="checkbox checkbox-icon-black p-0 text-left">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Product 1</label>
                      </div>
                      <div className="checkbox checkbox-icon-black p-0 text-left">
                        <input
                          id="checkbox2"
                          type="checkbox"
                          checked="checked"
                        />
                        <label for="checkbox2">Product 2</label>
                      </div>
                      <div className="checkbox checkbox-icon-black p-0 text-left">
                        <input id="checkbox3" type="checkbox" disabled />
                        <label for="checkbox3">Product 3</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 p-t-20">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-lg-6 text-left">
                          <label>Featured Products</label>
                        </div>
                        <div className="col-lg-6 text-right">
                          <a href="">View Details</a>
                        </div>
                      </div>
                      <div className="checkbox checkbox-icon-black p-0 text-left">
                        <input id="featureCheckbox1" type="checkbox" />
                        <label for="checkbox1">Featured 1</label>
                      </div>
                      <div className="checkbox checkbox-icon-black p-0 text-left">
                        <input
                          id="featureCheckbox2"
                          type="checkbox"
                          checked="checked"
                        />
                        <label for="checkbox2">Featured 1</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 p-t-20 text-left">
                    <button
                      type="button"
                      className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                      onClick={() => handleSave(item._id)}
                      >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};
