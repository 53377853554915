import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { deleteVendor } from "../../api/actions/VendorAction";
import swal from "sweetalert";

export const VendorTable = (props) => {
  const { tableData, minIndex, maxIndex } = props;

  const renderStatus = (status) => {
    switch (status) {
      case 0:
        return <span className="label label-sm label-warning">Pending</span>;
      case 1:
        return <span className="label label-sm label-success">Confirmed</span>;
      case -1:
        return <span className="label label-sm label-danger">Declined</span>;
      default:
        break;
    }
  };

  const handleDelete = (id) => {
    const { reRenderData } = props;
    deleteVendor(id).then((res) => {
      if (res.data.status == "success") {
        swal("Successfully Deleted!", "You clicked the button!", "success");
        reRenderData();
      }
    });
  };

  return (
    <Fragment>
      <div className="table-wrap">
        <div className="table-responsive">
          <table
            className="table display product-overview mb-30"
            id="support_table5"
          >
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, key) => {
                return (
                  <Fragment>
                    {key >= minIndex && key < maxIndex && (
                      <tr>
                        <td>{key + 1}</td>
                        <td>{item.name_of_business}</td>
                        <td>{item.email}</td>
                        <td>{item.phone_number}</td>
                        <td>{renderStatus(item.status)}</td>
                        <td>
                          <Link
                            to={{
                              pathname: "/vendorProducts",
                              state: item,
                            }}
                            className="btn btn-tbl-edit btn-xs"
                          >
                            <i className="fa fa-pencil"></i>
                          </Link>
                          <button
                            className="btn btn-tbl-delete btn-xs"
                            onClick={() => handleDelete(item._id)}
                          >
                            <i className="fa fa-trash-o "></i>
                          </button>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};
