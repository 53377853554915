import React from "react";

export const TableTools = (props) => {
  return (
    <div className="tools">
      <a className="fa fa-repeat btn-color box-refresh" href="#"></a>
      <a className="t-collapse btn-color fa fa-chevron-down" href="#"></a>
      <a className="t-close btn-color fa fa-times" href="#"></a>
    </div>
  );
};
