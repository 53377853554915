import React from "react";
import { Link } from "react-router-dom";
import { PageTitle } from "../Common/PageTitle";
import { NewApplication } from "./NewApplication";

class NewVendors extends React.Component {
  render() {
    return (
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <PageTitle title="All Applications" />
              <ol className="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i className="fa fa-home"></i>&nbsp;
                  <a className="parent-item" href="index.html">
                    Home
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li>
                  <a className="parent-item" href="">
                    Vendor Configurations
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li className="active">New Applications</li>
              </ol>
            </div>
          </div>
          <NewApplication />
        </div>
      </div>
    );
  }
}

export default NewVendors;
