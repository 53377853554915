import React, { useState, useEffect } from "react";
import { ComponentHeader } from "../Common/ComponentHeader";
import { TableTools } from "../Common/TableTools";
import {
  acceptVendor,
  getVendorList,
  rejectVendor,
} from "../../api/actions/VendorAction";
import { Alert } from "antd";
import swal from "sweetalert";
import { getCommissions } from "../../api/actions/Transactions";

export const NewApplication = (props) => {
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [packages, setPackages] = useState([]);
  const [paymentID, setPaymentID] = useState();

  useEffect(() => {
    getVendorList().then((res) => {
      if (res.data.vendors) {
        handleDataSet(res.data.vendors);
      } else {
        setError(true);
      }
    });
    getCommissions().then(res => {
      if (res.data.status == 'success') {
        let commissionArr = res?.data?.commissions
        commissionArr.sort((a, b) => a.commission_type - b.commission_type)
        setPackages(commissionArr);
      }
    })
  }, []);

  const handleDataSet = (data) => {
    let finalArr = [];
    data.forEach((element) => {
      if (element.status == 0) {
        finalArr.push(element);
      }
    });
    setTableData(finalArr);
  };

  const handleAccept = (id) => {
    let obj = {
      commissionId: selectedPackage,
      paymentId: paymentID
    }
    swal({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptVendor(id, obj).then((res) => {
          if (res.data.status == "success") {
            swal(`Successfully Accepted !`, {
              icon: "success",
            });
            getVendorList().then((res) => {
              if (res.data.vendors) {
                handleDataSet(res.data.vendors);
              } else {
                setError(true);
              }
            });
            setTimeout(() => {
              setVisible(false);
              setMessage("");
            }, 4000);
          } else {
            setMessage("Something went wrong !!!");
          }
        });
      } else {
        swal("Process Terminated!");
      }
    });
  };

  const handleReject = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once Updated, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        rejectVendor(id).then((res) => {
          if (res.data.status == "success") {
            swal(`Successfully Rejected !`, {
              icon: "success",
            });
            getVendorList().then((res) => {
              if (res.data.vendors) {
                handleDataSet(res.data.vendors);
              } else {
                setError(true);
              }
            });
            setTimeout(() => {
              setVisible(false);
              setMessage("");
            }, 4000);
          } else {
            setMessage("Something went wrong !!!");
          }
        });
      } else {
        swal("Process Terminated!");
      }
    });
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        {visible ? (
          <div style={{ marginBottom: "5px" }}>
            <Alert
              message={message}
              type="success"
              closable
              afterClose={handleClose}
            />
          </div>
        ) : null}
        <div className="card card-box">
          <div className="card-head">
            <ComponentHeader title="Vendor Name" />
            <TableTools />
          </div>
          {tableData.map((item, key) => {
            return (
              <div className="card-body row">
                <div className="col-lg-6 p-t-20">
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      className="mdl-textfield__input"
                      type="text"
                      id="txtModeltName"
                      defaultValue={item.name_of_business}
                    />
                  </div>
                </div>
                <div className="col-lg-6 p-t-20">
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      className="mdl-textfield__input"
                      type="text"
                      id="txtModeltEmail"
                      defaultValue={item.email}
                    />
                  </div>
                </div>
                <div className="col-lg-6 p-t-20">
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <select
                      onChange={(e) => setSelectedPackage(e.target.value)}
                      className="mdl-textfield__input"
                    >
                      <option value="">Select a commission package </option>
                      {packages?.map((item, key) => {
                        return (
                          <option
                            value={item._id}
                            key={key}
                            className="mdl-menu__item"
                          >
                            {item.package} | {item.commission} (KWD)
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 p-t-20">
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      className="mdl-textfield__input"
                      type="text"
                      id="txtModeltEmail"
                      placeholder="Payment ID"
                      onChange={e => setPaymentID(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 p-t-20 text-left">
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                    onClick={() => handleAccept(item._id)}
                  >
                    Accept & Create Account
                  </button>
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
                    onClick={() => handleReject(item._id)}
                  >
                    Reject Application
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
