import React, { useEffect, useState } from "react";
import { getDashboardData } from "../../api/actions/DashboardAction";
import { LastestOrderDetailsComponent } from "./LatestOrders";
import { NewVendors } from "./NewVendors";
import { LatestProducts } from "./LatestProducts";

export const Index = (props) => {
  const [vendorList, setVendorList] = useState([])
  const [ordersCount, setOrdersCount] = useState();
  const [orderCountToday, setOrderCountToday] = useState();
  const [registeredVendorsToday, setRegisteredVendorsToday] = useState();
  const [totalVendors, setTotalVendors] = useState();

  useEffect(() => {
    getDashboardData().then(res => {
      if (res.data.status == 'success') {
        setVendorList(res.data.newVendors);
        setOrdersCount(res.data.orderCount);
        setOrderCountToday(res.data.orderCountToday);
        setRegisteredVendorsToday(res.data.registeredVendorsToday);
        setTotalVendors(res.data.totalVendors);
      }
    })
  }, []);
  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <div className=" pull-left">
              <div className="page-title">Dashboard</div>
            </div>
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <a className="parent-item" href="index.html">
                  Home
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">Dashboard</li>
            </ol>
          </div>
        </div>
        {/* Widget - Start */}
        <div className="state-overview">
          <div className="row">
            <div className="col-xl-3 col-md-6 col-12">
              <div className="info-box bg-blue">
                <span className="info-box-icon push-bottom">
                  <i className="material-icons">style</i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Total Orders Placed</span>
                  <span className="info-box-number">{ordersCount}</span>
                  <div className="progress">
                    <div className="progress-bar width-60"></div>
                  </div>
                </div>
                {/* <!-- /.info-box-content --> */}
              </div>
              {/* <!-- /.info-box --> */}
            </div>
            {/* <!-- /.col --> */}
            <div className="col-xl-3 col-md-6 col-12">
              <div className="info-box bg-success">
                <span className="info-box-icon push-bottom">
                  <i className="material-icons">monetization_on</i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Today Orders Count</span>
                  <span className="info-box-number">{orderCountToday}</span>
                  {/* <span>$</span> */}
                  <div className="progress">
                    <div className="progress-bar width-60"></div>
                  </div>
                </div>
                {/* <!-- /.info-box-content --> */}
              </div>
              {/* <!-- /.info-box --> */}
            </div>
            {/* <!-- /.col --> */}
            <div className="col-xl-3 col-md-6 col-12">
              <div className="info-box bg-orange">
                <span className="info-box-icon push-bottom">
                  <i className="material-icons">card_travel</i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Registered Vendors for the Day</span>
                  <span className="info-box-number">{registeredVendorsToday}</span>
                  {/* <span>$</span> */}
                  <div className="progress">
                    <div className="progress-bar width-40"></div>
                  </div>
                </div>
                {/* <!-- /.info-box-content --> */}
              </div>
              {/* <!-- /.info-box --> */}
            </div>
            {/* <!-- /.col --> */}
            <div className="col-xl-3 col-md-6 col-12">
              <div className="info-box bg-purple">
                <span className="info-box-icon push-bottom">
                  <i className="material-icons">check_circle</i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Total Vendors</span>
                  <span className="info-box-number">{totalVendors}</span>
                  <div className="progress">
                    <div className="progress-bar width-80"></div>
                  </div>
                </div>
                {/* <!-- /.info-box-content --> */}
              </div>
              {/* <!-- /.info-box --> */}
            </div>
            {/* <!-- /.col --> */}
          </div>
        </div>
        {/* Widget - End  */}
        <LastestOrderDetailsComponent />
        <NewVendors tableData={vendorList}/>
        <LatestProducts />
      </div>
    </div>
  );
};
