import React, { Fragment } from "react";

export const ProfessionalsTable = (props) => {
  const { tableData, minIndex, maxIndex } = props;

  const handleAccept = (id) => {
    props.acceptProfessional(id);
  };

  const handleReject = (id) => {
    props.rejectProfessional(id);
  };

  return (
    <Fragment>
      <div className="table-wrap">
        <div className="table-responsive">
          <table className="table display product-overview mb-30" id="">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((item, key) => {
                  return (
                    <Fragment>
                      {key >= minIndex && key < maxIndex && (
                        <tr>
                          <td>{key + 1}</td>
                          <td>{item.fullName}</td>
                          <td>{item.email}</td>
                          <td>{item?.phone_number}</td>
                          <td>
                            {item?.accepted != true ? (
                              <button
                                type="button"
                                className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                                onClick={() => handleAccept(item?._id)}
                              >
                                Accept
                              </button>
                            ) : (
                              ""
                            )}
                            <button
                              type="button"
                              className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
                              onClick={() => handleReject(item?._id)}
                            >
                              Reject
                            </button>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};
