import {
    IS_FETCHING,
    DONE_FETCHING,
    GET_PROFESSIONAL,
    GET_PROFESSIONALS,
  } from "../Actions/ActionTypes";

  const initialState = {
    isFetching: false,
    authenticated: null,
    professionals: [],
    professional: {},
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case IS_FETCHING:
        return { ...state, isFetching: true };
      case DONE_FETCHING:
        return { ...state, isFetching: false };
      case GET_PROFESSIONALS:
        return { ...state, professionals: action.payload };
      case GET_PROFESSIONAL:
        return { ...state, professional: action.payload };
      default:
        return state;
    }
  }